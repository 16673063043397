import { useState, useEffect, useRef } from "react";

import Layout from "../../../components/layout";

import { FaDownload } from "react-icons/fa";
import { adminService } from "../../../api/admin";

import styles from "./style.module.scss";
import { useLocation } from "react-router-dom";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import PdfCreateExam from "../../../components/pdfCreateExam";

export default function ExamsAdminView() {
  const [exams, setExams] = useState();
  const [pagination, setPagination] = useState(0);
  const [pages, setPages] = useState(null);

  const defaultSearch = useRef();
  const { pathname } = useLocation();

  let filterByPath = "";
  if (pathname === "/admin/examspassed") {
    filterByPath = "admin";
  }
  if (pathname === "/admin/examspassed-tutor") {
    filterByPath = "tutor";
  }

  let token = localStorage.getItem("token");
  let numberPerPage = 200;

  const loadExams = (defaultSearch = "") => {
    let params = {
      token: token,
      page: pagination,
      defaultSearch: defaultSearch,
      filterByPath: filterByPath,
    };

    adminService
      .exams(params)
      .then((response) => {
        setExams(response?.data?.payload?.data);
        if (response?.data?.payload?.count) {
          setPages(Math.ceil(response?.data?.payload?.count / numberPerPage));
        }
      })
      .catch((error) => {});
  }; //loadExams

  useEffect(() => {
    loadExams();
    // eslint-disable-next-line
  }, [pathname, pagination]);

  const nextPage = () => {
    setPagination(pagination + 1);
  };

  const prevPage = () => {
    setPagination(pagination - 1);
  };

  const goToPage = (item) => {
    setPagination(item - 1);
  };

  let arr = [];

  const getPagination = () => {
    if (pages) {
      for (let i = 1; i <= pages; i++) {
        arr.push(i);
      }
      return arr;
    }
  };

  const goToSearch = () => {
    //if(defaultSearch.current.value === ''){ return true; }
    loadExams(defaultSearch.current.value);
    defaultSearch.current.value = "";
  }; //goToSearch

  const generatePDFDocument = (item) => {
    const idQuizAttempt = item.id;

    adminService
      .getFullQuizAttempt({ token, idQuizAttempt })
      .then(async (response) => {
        const blob = await pdf(
          <PdfCreateExam data={item} questions={response?.data?.payload.data} />
        ).toBlob();
        saveAs(
          blob,
          (
            item.examDate +
            " " +
            item.surname +
            " " +
            item.name +
            " " +
            item.title +
            " " +
            item.courseCode +
            " "
          )
            .toLocaleLowerCase()
            .replace(/ /g, "_")
        );
      })
      .catch((error) => {});
  };

  const getPaymentType = (type) => {
    switch (type.toLocaleLowerCase()) {
      case "paypal":
        return "paypal";
      case "stripe":
        return "stripe";
      case "banktransfert":
        return "bonifico";
      case "tutor":
        return "prepagato";
      default:
        break;
    }
  }; //getpaymentType

  const formatDate = (date) => {
    if (date === null) {
      return true;
    }
    const dataString = date;
    const data = new Date(dataString);
    const day = String(data.getDate()).padStart(2, "0");
    const month = String(data.getMonth() + 1).padStart(2, "0");
    const year = String(data.getFullYear());

    return `${day}/${month}/${year}`;
  };

  if (typeof exams === "undefined") {
    return false;
  }

  //--- ---

  return (
    <>
      <Layout>
        <div className="mx-auto">
          <div>
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                ref={defaultSearch}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    goToSearch();
                  }
                }}
                className="block w-full p-4 pl-10 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="nome / cognome / codice fiscale"
              />
              <button
                type="submit"
                onClick={(e) => {
                  goToSearch();
                }}
                className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 uppercase">
                cerca
              </button>
            </div>
            <hr className="my-2 h-1 mx-auto bg-blue-100" />
          </div>

          <table className="w-full border-collapse border border-slate-500 text-left">
            <thead>
              <tr>
                <th className="border border-slate-600 p-2">Nome e cognome</th>
                <th className="border border-slate-600 p-2">Data Esame</th>
                <th className="border border-slate-600 p-2">Test</th>
                <th className="border border-slate-600 p-2">Codice Fiscale</th>
                {localStorage.getItem("role") === "admin" && (
                  <>
                    <th className="border border-slate-600 p-2">
                      Data Nascita
                    </th>
                    <th className="border border-slate-600 p-2">Pagato</th>
                  </>
                )}
                <th className="border border-slate-600 p-2">Azioni</th>
              </tr>
            </thead>
            <tbody>
              {(exams || []).map((item, index) => (
                <tr className="even:bg-gray-70 odd:bg-white" key={index}>
                  <td className="border border-slate-700 p-2 py-4">
                    {item?.name + " "} {item?.surname}
                  </td>
                  <td className="border border-slate-700 p-2 py-4">
                    {item?.examDate !== null ? formatDate(item?.examDate) : ""}
                  </td>
                  <td className="border border-slate-700 p-2 py-4">
                    {item?.title}
                    <p className="text-xs font-semibold">
                      corso: {item?.courseCode}
                    </p>
                    <p className="text-xs font-semibold">
                      codice: {item?.registrationCode}
                    </p>
                  </td>
                  <td className="border border-slate-700 p-2 py-4">
                    {item?.fiscalCode}
                  </td>

                  {localStorage.getItem("role") === "admin" && (
                    <>
                      <td className="border border-slate-700 p-2 py-4">
                        {item?.dateOfBirth !== null
                          ? formatDate(item?.dateOfBirth)
                          : ""}
                      </td>
                      <td className="border border-slate-700 p-2 py-4">
                        <span
                          className={`${
                            item?.isPayed === 1
                              ? "text-green-600"
                              : "text-red-600"
                          } font-semibold`}>
                          {item?.isPayed === 1
                            ? "SI"
                            : item?.paymentType === "tutor"
                            ? "SI"
                            : "NO"}
                        </span>
                        <p className="text-xs font-semibold">
                          {getPaymentType(item?.paymentType)}
                        </p>
                      </td>
                    </>
                  )}

                  <td className="border border-slate-700 p-2 py-4">
                    <div className="grid grid-cols-3 gap-2">
                      <div
                        className="cursor-pointer"
                        onClick={() => generatePDFDocument(item)}>
                        <FaDownload size={24} />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={`${styles.pagination} flex flex-wrap justify-center`}>
            <div className="cursor-pointer" onClick={() => prevPage()}>
              Indietro
            </div>
            {getPagination()?.map((item, index) => (
              <div
                className={`${styles.pageNumber} ${
                  pagination === index ? styles.pageActive : ""
                }`}
                onClick={() => goToPage(item)}
                key={index}>
                {item}
              </div>
            ))}
            {pagination < pages && (
              <div className="cursor-pointer" onClick={() => nextPage()}>
                Avanti
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
}
