import { Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/layout";

export default function WrongPaymentTutor() {
  let navigate = useNavigate();

  return (
    <Layout>
      <div className="container mx-auto">
        <div className="mt-4">
          <p className="text-2xl font-bold mb-4 text-red-500">
            Il pagamento non è andato a buon fine.
          </p>
          <p>
            Purtroppo il pagamento non è andato a buon fine. <br />
            Riprova o contattaci.
          </p>
          <div className="mt-4">
            <Button
              onClick={() =>
                navigate("/admin/priceList", {})
              }
            >
              Torna al pagamento
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
}
