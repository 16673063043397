import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";

function CourseCard(props) {
  // props
  const {
    code,
    title,
    buttonLabel,
    isActiveCourse,
    userVersion
  } = props || {};

  return (
    <>
      <Card className="mt-20 md:mt-0 cursor-pointer">
        <CardHeader className={`relative ${(userVersion < 2) ? 'h-56' : 'h-96'} }`}>
          <img
            src={"/images/dashboard/courses/" + code + ".jpeg"}
            alt="immagine"
            className="w-full"
          />
        </CardHeader>
        <CardBody>
          {(userVersion < 2) ? <Typography variant="h5" className="mb-2"> {title} </Typography> : <Typography variant="h4" className="text-center"> {title} </Typography>}
        </CardBody>
        <CardFooter divider className="flex items-center justify-between py-3">
          <Button
            type="submit"
            className={`${isActiveCourse ? "bg-green-600" : "bg-indigo-600"
              } m-0 mx-auto group relative flex justify-center rounded-md border border-transparent py-4 px-10 text-md font-bold text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
          >
            {buttonLabel}
          </Button>
        </CardFooter>
      </Card>
    </>
  );
}

export default CourseCard;
