import { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routing";

import "react-whatsapp-chat-widget/index.css";
import "./App.css";

import { UserProvider } from "./context/userContext";

function App() {
  const [token, setToken] = useState(false);

  return (
    <>
      <UserProvider>
        <Router>
          <AppRoutes token={token} setToken={setToken} />
        </Router>
      </UserProvider>
    </>
  );
}

export default App;
