import { proxyBE } from "./common";

export const adminService = {
  // users
  getUsers(params) {
    return proxyBE.get("dashboard/user/load/", { params });
  },
  getAppConfig(params) {
    return proxyBE.get("dashboard/appConfig/load/", { params });
  },
  setAppConfig(params) {
    return proxyBE.post("dashboard/appConfig/update/", params);
  },
  getUserCourseStatus(params) {
    return proxyBE.get("dashboard/user/loadCourseStatus/", { params });
  },
  getUserLog(params) {
    return proxyBE.get("dashboard/user/loadUserLog/", { params });
  },
  deleteUser(params) {
    return proxyBE.post("dashboard/user/delete", params);
  },
  detailUser(params) {
    return proxyBE.get("dashboard/user/detail", { params });
  },
  updatCertificate(params) {
    return proxyBE.post("dashboard/certificate/update/", params);
  },

  // exams
  exams(params) {
    return proxyBE.get("dashboard/exam/load/", { params });
  },
  getFullQuizAttempt(params) {
    return proxyBE.get("dashboard/exam/loadFullQuizAttempt/", { params });
  },

  // certificates
  certificates(params) {
    return proxyBE.get("dashboard/certificate/load/", { params });
  },
  detailCertificate(params) {
    return proxyBE.get("dashboard/certificate/detail", { params });
  },
  getCalendarDataCount(params) {
    return proxyBE.get("dashboard/certificate/loadCalendarDataCount/", { params });
  },
  downloadCertificatesDailyReport(params) {
    return proxyBE.get("dashboard/certificate/downloadCertificatesDailyReport/", { params });
  },

  // coupons
  getCoupons(params) {
    return proxyBE.get("dashboard/bonus/load/", { params });
  },
  addCoupon(data) {
    return proxyBE.post("dashboard/bonus/insert", data);
  },
  deleteCoupon(data) {
    return proxyBE.post("dashboard/bonus/delete", data);
  },

  // courses
  getCourses(params) {
    return proxyBE.get("dashboard/course/load/", { params });
  },
  updateCourse(data) {
    return proxyBE.post("dashboard/course/update", data);
  },
  getDomain(params) {
    return proxyBE.get("dashboard/domain/load/", { params });
  },
  updateDomain(data) {
    return proxyBE.post("dashboard/domain/update", data);
  },

  // tutor
  registerTutor(data) {
    return proxyBE.post("dashboard/tutor/register", data);
  },
  cartInsert(data) {
    return proxyBE.post("dashboard/tutor/cartInsert", data);
  },
  getCart(params) {
    return proxyBE.get("dashboard/tutor/cartLoad/", { params });
  },
  getCredits(params) {
    return proxyBE.get("dashboard/tutor/creditLoad/", { params });
  },
  registerStudent(data) {
    return proxyBE.post("dashboard/tutor/studentRegister", data);
  },
  getUserById(params) {
    return proxyBE.get("dashboard/tutor/userByIdLoad/", { params });
  },
  setCertificateGift(data) {
    return proxyBE.post("dashboard/tutor/setCertificateGift", data);
  },
  uploadTutorLogo(data) {
    return proxyBE.post("dashboard/tutor/uploadlogo", data);
  },
};
