import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// components
import LoginForm from "../../components/loginForm";
import PrivacyPolicy from "../../components/privacyPolicy";
import { useForm } from "react-hook-form";
import { userService } from "../../api/user";

import {
  Input,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
} from "@material-tailwind/react";
import styles from "./style.module.scss";

export default function LoginView({ setUserToken, token, setToken }) {
  const [dialogPrivacy, setDialogPrivacy] = useState(false);
  const [isDialogForgotPassword, setIsDialogForgotPassword] = useState(false);
  const [isSended, setIsSended] = useState(false);
  const [/*email*/, setEmail] = useState();

  const { loginByToken } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    userService
      .recoveryPassword({ email: data?.email })
      .then((response) => {
        setIsSended(true);
      })
      .catch((error) => {});
  };

  const toggleDialogPrivacy = () => {
    setDialogPrivacy(!dialogPrivacy);
  };

  const toggleDialogForgotPassword = () => {
    setIsDialogForgotPassword(!isDialogForgotPassword);
  };

  /** verify login by token */
  useEffect(()=>{
    token = loginByToken;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginByToken ])

  return (
    <div
      className={`${styles.container} flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gradient-to-b from-blue-800 to-blue-500`}
    >
      <div className="max-w-lg w-full">
        <LoginForm
          setUserToken={setUserToken}
          setToken={setToken}
          token={token || loginByToken || false}
          toggleDialogForgotPassword={toggleDialogForgotPassword}
        />
        <div
          className="text-center mt-4 text-white cursor-pointer"
          onClick={toggleDialogPrivacy}
        >
          Privacy Policy
        </div>
      </div>
      <Dialog
        open={isDialogForgotPassword}
        handler={toggleDialogForgotPassword}
        size={"lg"}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="w-full p-2 md:p-4">
          <DialogHeader>
            <span className="cursor-pointer">Hai dimenticato la password?</span>
          </DialogHeader>
          <DialogBody>
            {!isSended ? (
              <Input
                label="Email"
                type="email"
                {...register("email", {
                  required: true,
                  pattern: /^\S+@\S+$/i,
                  onChange: (e) => setEmail(e.target.value),
                })}
              />
            ) : (
              <p className="green">
                Abbiamo inviato la password alla tua email.
              </p>
            )}
          </DialogBody>
          <DialogFooter>
            <Button onClick={toggleDialogForgotPassword}>Chiudi</Button>
            {!isSended && (
              <Button
                className="ml-2"
                variant="gradient"
                color="green"
                type="submit"
              >
                <span>Invia</span>
              </Button>
            )}
          </DialogFooter>
        </form>
      </Dialog>

      <Dialog open={dialogPrivacy} handler={toggleDialogPrivacy} size={"xxl"}>
        <DialogHeader>Privacy Policy</DialogHeader>

        <PrivacyPolicy />
        <DialogFooter>
          <Button onClick={toggleDialogPrivacy}>Chiudi</Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
}
