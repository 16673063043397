function PrivacyPolicy() {
    return (
        <div className="overflow-auto p-2">
          <h1 className="text-2xl uppercase font-bold">
            {"Privacy Policy e Trattamento dei dati personali"}
          </h1>
          <div className="mt-8">
            <p className="font-bold">
              {
                "Informativa ai sensi dell'art. 13 del regolamento (UE) 2016/679"
              }
            </p>
            <p>
              {
                "La Ditta LAB.MAR. del Dott. Calogero Cecato, in qualità di TITOLARE DEL TRATTAMENTO DATI, le rilascia ai sensi della normativa vigente le informazioni relative al trattamento dei dati personali suoi e del soggetto del quale Lei esercita la rappresentanza legale. Conformemente a quanto disciplinato dall’art. 13 del Regolamento UE 2016/679, desideriamo comunicarvi che il trattamento dei dati personali è effettuato soltanto con il suo consenso e per le finalità sotto riportate. Il consenso ha validità permanente ma potrà essere modificato o revocato in qualsiasi momento come riportato di seguito."
              }
            </p>
          </div>
          <div className="mt-4">
            <ol className="flex flex-col gap-4">
              <li>
                {
                  "Finalità del Trattamento dati: raccolta e conservazione dati in archivi elettronici per la fornitura di prodotti e servizi relativi al sito web; attività commerciali e relativi obblighi fiscali collegati alla fatturazione dei servizi prestati; invio di comunicazioni di servizio inerenti i servizi offerti, newsletter e iniziative promozionali di proprie attività, servizi e obblighi di legge."
                }
              </li>
              <li>
                {
                  "Modalità trattamento dati: sarà effettuato con le modalità informatizzato e manuale."
                }
              </li>
              <li>
                {
                  "Trasferimento dei dati: Nei limiti pertinenti alle finalità di trattamento indicate, i suoi dati personali potranno essere trattati dai responsabili esterni, incaricati da LAB.MAR. del Dott. Calogero Cecato, per adempiere agli obblighi di legge, fiscali e/o contabili."
                }
              </li>
              <li>
                {
                  "Tempi di conservazione: nel rispetto di quanto previsto dall’art. 5 comma 1 lett. e) del Reg. UE 2016/679, i dati personali raccolti verranno conservati in una forma che consenta l’identificazione degli interessati per un arco di tempo non superiore ai 10 anni, sebbene i dati relativi ad i corsi svolti in materia di salute e sicurezza sul lavoro verranno conservati per un tempo indeterminato al fine di risultare disponibili in caso di controlli ispettivi o di richiesta esplicita del corsista."
                }
              </li>
              <li>
                {
                  "Conseguenza del Consenso e del mancato consenso: il conferimento dei dati è obbligatorio e il loro eventuale mancato conferimento potrebbe comportare la mancata prosecuzione del rapporto."
                }
              </li>
              <li>
                {
                  "Diritti degli interessati: in ogni momento l’interessato potrà esercitare i diritti di cui agli art.15,16,17,18, 19, 20 del Regolamento UE 2016/679 presentando istanza scritta mediante la mail"
                }{" "}
                <a href="mailto:privacylabmar@gmail.com" className="underline">
                  privacylabmar@gmail.com.
                </a>{" "}
                {
                  "e precisamente potrà accedere alle informazioni che lo riguardano e conoscere finalità, categorie di dati personali trattati, destinatari a cui i dati sono stati o saranno comunicati ed il periodo di conservazione dei dati stessi.ì L’interessato potrà inoltre avvalersi del diritto di proporre un reclamo alle autorità di controllo, potrà chiedere la rettifica, la cancellazione (diritto all’oblio), e le limitazioni di trattamento dei propri dati personali. Tali rettifiche o cancellazioni dei dati personali o limitazioni del trattamento dovranno essere comunicate a tutti i destinatari cui sono stati trasmessi i dati; l’interessato ha inoltre il diritto di avvalersi della portabilità dei propri dati come previsto dalla normativa vigente."
                }
              </li>
              <li>
                {
                  "I dati non saranno comunicati ad altri soggetti, se non chiedendoLe espressamente il consenso."
                }
              </li>
              <li>
                {
                  "I dati potranno essere comunicati a soggetti preposti a controlli e verifiche ispettive in materia."
                }
              </li>
            </ol>
          </div>
          <div className="mt-8">
            <p className="text-2xl font-bold">{"Utilizzo dei Cookies"}</p>
          </div>
          <div className="mt-4">
            <p className="font-bold">{"Cosa sono i cookies"}</p>
            <p>
              {
                "Un 'cookie' è un file di testo salvato nel computer dell'utente nel momento in cui questo accede ad un sito web con lo scopo di fornire informazioni ogni volta che l'utente ritorna sullo stesso sito. E' una sorta di promemoria della pagina internet visitata. Con il cookie, il server web invia informazioni al browser dell'utente (Internet Explorer, Mozilla Firefox, Google Chrome, ecc.) memorizzate sul computer di quest'ultimo, e saranno rilette ed aggiornate ogni qual volta l'utente ritornerà sul sito. In questo modo il sito Web può adattarsi automaticamente all'utente. Nel corso della navigazione l'utente potrebbe ricevere sul suo terminale anche cookie di siti diversi (cookies di 'terze parti'), impostati direttamente da gestori di detti siti web e utilizzati per le finalità e secondo le modalità da questi definiti. "
              }
            </p>
            <p className="mt-2">
              {
                "In funzione della loro durata, si distinguono in cookie di sessione (ossia quelli temporanei e cancellati automaticamente dal terminale al termine della sessione di navigazione, chiudendo il browser) ed in cookie persistenti (ossia quelli che restano memorizzati sul terminale fino alla loro scadenza o cancellazione da parte dell'utente). In base alla funzione e alla finalità di utilizzo, i cookie possono suddividersi in cookie tecnici e cookie di profilazione."
              }
            </p>
          </div>
          <div className="mt-4">
            <p className="font-bold">{"Cookie tecnici"}</p>
            <p>
              {
                "Alcuni cookie sono usati per eseguire autenticazioni informatiche, monitoraggio di sessioni e memorizzazione di informazioni specifiche sugli utenti che accedono ad una pagina web. Questi cookie, cosiddetti tecnici, sono spesso utili per consentire di navigare in un sito web e utilizzarne tutte le funzionalità. I cookie tecnici sono quelli il cui utilizzo non richiede il consenso dell'utente. A questa categoria appartengono anche gli analytics cookie. Si tratta di cookie che raccolgono informazioni circa l'utilizzo che un utente fa di un sito web e che consentono di migliorarne il funzionamento. Ad esempio gli analytics cookie mostrano quali sono le pagine più frequentemente visitate, consentono di verificare quali sono gli schemi ricorrenti d'utilizzo di un sito web e aiutano a comprendere ogni difficoltà che l'utente incontra nell'utilizzo."
              }
            </p>
          </div>
          <div className="mt-4">
            <p className="font-bold">{"Cookie di profilazione"}</p>
            <p>
              {
                "Altri cookie possono invece essere utilizzati per monitorare e profilare gli utenti durante la navigazione, studiare i loro movimenti e abitudini di consultazione del web o di consumo (cosa comprano, cosa leggono, ecc.), anche allo scopo di inviare pubblicità di servizi mirati e personalizzati. Si parla in questo caso di cookie di profilazione. L'utilizzo di detti cookie necessita dell'acquisizione preventiva del libero consenso informato dell'utente ai sensi dell'art. 7 del Regolamento UE 2016/679 e della normativa nazionale in vigore."
              }
            </p>
          </div>
          <div className="mt-4">
            <p className="font-bold">{"Cookie di terze parti"}</p>
            <p>
              {
                "Può accadere anche che una pagina web contenga cookie provenienti da altri siti e contenuti in vari elementi ospitati sulla pagina stessa, come ad esempio banner pubblicitari, immagini, video, mappe o specifici link a pagine web di altri domini che risiedono su server diversi da quello sul quale si trova la pagina richiesta. In altre parole, questi cookie sono impostati direttamente da gestori di siti web o server diversi da questo sito web. Parliamo, in questi casi, dei cosiddetti cookie terze parti, che di solito sono utilizzati a fini di profilazione. L'utilizzo di questi cookie necessita dell'acquisizione preventiva del libero consenso informato dell'utente."
              }
            </p>
          </div>
          <div className="mt-4">
            <p className="font-bold">
              {"Tipologie di cookie utilizzati dal nostro sito"}
            </p>
            <p>
              {
                "Le funzioni principali dei cookies installati labmar.it sono tecniche, vengono utilizzati per migliorare costantemente il Servizio, per l'autenticazione, per fini statistici, come il conteggio delle visite al sito. L'utilizzo di cookies è dunque strettamente finalizzato a facilitare le funzioni del server di durante la navigazione del Servizio."
              }
            </p>
            <p>
              {
                "Il sito consente inoltre l'invio dei seguenti cookie di terze parti. Questi cookie non sono strumenti di nostra titolarità, per maggiori informazioni, pertanto, è possibile accedere all'informativa ed ai moduli di acquisizione del consenso delle terze parti, cliccando sui link riportati."
              }
            </p>
            <p>
              {
                "Per migliorare il sito web e comprendere quali parti o elementi siano maggiormente apprezzati dagli utenti, sono utilizzati come strumento di analisi anonima e aggregata, i cookie di terza parte di Google Analytics. Questi cookie non sono strumenti di nostra titolarità, per maggiori informazioni, pertanto, è possibile consultare l'informativa fornita da Google."
              }
            </p>
          </div>
          <div className="mt-4">
            <p className="font-bold">{"Gestione dei cookie"}</p>
            <p>
              {
                "L'utente può gestire le proprie preferenze relative ai cookie attraverso le funzionalità presenti nei comuni browser che consentono di cancellare/rimuovere i cookie (tutti o alcuni) o di cambiare le impostazioni del browser stesso in modo da bloccare l'invio dei cookie o di limitarlo a siti specifici (rispetto ad altri). Pertanto è possibile negare l'utilizzo dei cookie, seguendo la procedura di disabilitazione prevista dal proprio browser."
              }
            </p>
            <p className="italic mt-4">
              {
                "La presente pagina può subire modifiche nel tempo – anche connesse all'eventuale entrata in vigore di nuove normative di settore, all'aggiornamento o erogazione di nuovi servizi ovvero ad intervenute innovazioni tecnologiche – per cui l'utente/visitatore è invitato a consultare periodicamente questa pagina."
              }
            </p>
          </div>
        </div>
    )
}

export default PrivacyPolicy;