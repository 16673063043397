import React, { useState, useEffect } from "react";

import { adminService } from "../../api/admin";
import UploadTutorLogo from "./uploadTutorLogo";

export default function AccountDetailView(props) {
  const token = localStorage.getItem("token");
  const [user, setUser] = useState();
  const [currentState, setCurrentState] = useState(true);


  const getUser = () => {
    adminService
      .getUserById({
        token,
        userId: props?.userId || 0,
      })
      .then((response) => {
        //console.log(response?.data?.payload?.data);
        setUser(response?.data?.payload?.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, [setUser]);

  //--- ---

  const formatDate = (date) => {
    if (date === null) {
      return true;
    }
    const dataString = date;
    const data = new Date(dataString);
    const day = String(data.getDate()).padStart(2, "0");
    const month = String(data.getMonth() + 1).padStart(2, "0");
    const year = String(data.getFullYear());

    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    //eslint-disable-next-line    
  }, [currentState]);


  if (!user) {
    return null;
  }

  //--- ---

  return (
    <>
      <div className="container mx-auto">
        <div className="flex">
          <div className="w-2/3">
            <div className="p-4 bg-white rounded-xl">
              {(user?.fiscalCode || !user?.taxId) && (
                <>
                  <p className="font-bold">
                    {user?.uName} {user?.uSurname}
                  </p>
                  <p>
                    {user?.birthCity && `Nato a ${user?.birthCity}`}{" "}
                    {user?.dateofBirth !== null &&
                      `il ${formatDate(user?.dateofBirth)}`}
                  </p>
                  {user?.fiscalCode && (
                    <p>Codice Fiscale: {user?.fiscalCode}</p>
                  )}
                </>
              )}
              {user?.taxId && (
                <>
                  <p className="font-bold">{user?.companyName}</p>
                  <p>P.IVA: {user?.taxId}</p>
                </>
              )}
              <p className="mt-4 font-bold">Indirizzo</p>
              <p>
                {user?.address} {user?.houseNumber}
                {user?.residenceCity && `, ${user?.residenceCity}`}{" "}
                {user?.residenceProvince && `(${user?.residenceProvince})`}
              </p>
              <p>{user?.zipcode && `CAP: ${user?.zipcode}`}</p>
              <p>{user?.email && `email: ${user?.email}`}</p>
              <p>{user?.pec && `PEC: ${user?.pec}`}</p>
              <p>{user?.phoneMobile && `telefono: ${user?.phoneMobile}`}</p>
              <p className="mt-4">
                {user?.domain && `dominio: ${user?.domain}`}
              </p>

              {user.role === "tutor" && (
                <>
                  <p className="mt-2 font-bold">Logo Tutor:</p>
                  <img style={{maxHeight:'95px'}} src={process.env.REACT_APP_API_URL + "/upload/tutorLoghi/" + props?.userId + ".jpg?" + Math.random()} alt="Logo Tutor" onError={(e) => e.target.style.display = 'none'} />
                </>
              )}
            </div>
          </div>
          {user.role === "tutor" && (
            <div className="w-1/3">
              <UploadTutorLogo userId={props?.userId} setCurrentState={setCurrentState} currentState={currentState} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
