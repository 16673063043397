import create from "zustand";

const useDashboardStore = create(() => (
    {
        dashboard: {
            credits: 0
        }
    }
))

export default useDashboardStore;