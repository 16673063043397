import React, { useRef, useState } from "react";

// import { DefaultPlayer as Video } from "react-html5video";

function VideoPlayer(props) {
  const {
    src,
    freeCourse,
    toggleModalLesson,
    handleLessonEnded,
    setIsModalAlertOpen,
  } = props || null;

  const [/*time*/, setTime] = useState(0);

  const videoRef = useRef();

  let supposedCurrentTime = 0;

  const videoHandler = (evt) => {
    let video = evt?.target;
    //const duration = evt?.target?.duration;
    //const progress = duration * 0.9; //-- 90% of video is considered lesson viewed

    video.addEventListener("timeupdate", function () {
      setTime(video.currentTime);
      if (!video.seeking) {
        supposedCurrentTime = video.currentTime;
      }
    });

    // prevent user from seeking
    video.addEventListener("seeking", function () {
      var delta = video.currentTime - supposedCurrentTime;
      if (delta > 0.01 && freeCourse === 0) {
        video.currentTime = supposedCurrentTime;
      }
    });

    const endVideo = () => {
      //-- close video and modal
      handleLessonEnded();
      toggleModalLesson(false);
      setIsModalAlertOpen(false);
    };

    if (freeCourse === 1) {
      handleLessonEnded();
    }

    video.addEventListener(
      "ended",
      () => {
        setTimeout(endVideo, 1000);
      },
      false
    );
  };

  return (
    <video
      autoPlay
      muted
      playsInline
      controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
      width="100%"
      height="100%"
      style={{ maxHeight: "80vh" }}
      poster="images/logo.png"
      ref={videoRef}
      onCanPlayThrough={(evt) => {
        videoHandler(evt);
      }}
    >
      <source src={src} type="video/mp4" />
    </video>
  );
}

export default VideoPlayer;
