import Layout from "../../components/layout";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { certificateService } from "../../api/certificate";
import { formatDate } from "../../utils/date";

export default function VerifyQrCodeView() {
  const location = useLocation();
  const code = new URLSearchParams(location.search).get("verify");
  const [certificate, setCertificate] = useState("");

  const getCertificate = () => {
    certificateService
      .verifyQrCode({ verify: code })
      .then((response) => {
        setCertificate(response?.data?.payload);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getCertificate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCertificate]);

  return (
    <Layout>
      <div className="container mx-auto">
        <div className="mt-4 bg-white p-4">
          {certificate ? (
            <div className="text-center">
              <p>
                Certificato n.{" "}
                <span className="font-bold">{certificate?.progress}</span> del{" "}
                <span className="font-bold">{certificate?.aa}</span>
              </p>
              <p>
                Sostenuto da <br />{" "}
                <span className="font-bold">
                  {certificate?.name} {certificate?.surname}
                </span>
              </p>
              <p>
                Rilascito in data{" "}
                <span className="font-bold">
                  {formatDate(certificate?.releasedAt)}
                </span>
              </p>
              <p>
                Valido fino al{" "}
                <span className="font-bold">
                  {formatDate(certificate?.expiredAt)}
                </span>
              </p>
              <div className="mt-4 mx-auto">
                {/* <QRcode
                    value={
                      "https://app.labmar.it/backend/v1/userCertification/verifyCheck/?verify="+certificate.code+""
                    }
                    id="qrcode"
                    className="mx-auto"
                  /> */}
              </div>
            </div>
          ) : (
            <p className="text-center">Nessun certificato valido trovato</p>
          )}
        </div>
      </div>
    </Layout>
  );
}
