import React from "react";
import Layout from "../../components/layout";

export default function CertificateView() {
    const token = localStorage.getItem("token");

    return(
        <Layout>
            <div className="container mx-auto">
                Certificate
            </div>
        </Layout>
    )
}