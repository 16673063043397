import { useState, useEffect } from "react";
import { FaRegEdit } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { adminService } from "../../../api/admin";
import Layout from "../../../components/layout";

import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
} from "@material-tailwind/react";

export default function CoursesAdminView() {
  const [editDialog, setEditDialog] = useState(false);
  const [courses, setCourses] = useState(null);
  const [course, setCourse] = useState(null);
  const [domains, setDomains] = useState(null);

  let token = localStorage.getItem("token");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({});

  const getCourses = () => {
    adminService
      .getCourses({ token })
      .then((response) => {
        if (response?.data?.payload) {
          setCourses(response?.data?.payload);
        }
      })
      .catch((error) => {
        setCourses(false);
      });
  };

  const getDomain = (courseID) => {
    adminService
      .getDomain({ token, courseID })
      .then((response) => {
        if (response?.data?.payload) {
          setDomains(response?.data?.payload);
        }
      })
      .catch((error) => {
        setDomains(false);
      });
  };

  useEffect(() => {
    getDomain((course?.id || 0));
    getCourses();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {

    domains?.data?.filter((domain) => { return domain.isActive === 1 }).map((domain, index) => {
      setValue(`price-${domain.id}`, domain.price);
      setValue(`priceDiscount-${domain.id}`, domain.priceDiscount);

    // eslint-disable-next-line
      return true;
    })

  }, [domains]);

  const onSubmit = (data) => {

    const params = {
      token,
      idCourse: course?.id,
      price: data?.price,
      priceDiscount: data?.priceDiscount,
      others: { ...data }
    };

    delete params.others.price;
    delete params.others.priceDiscount;

    adminService
      .updateCourse(params)
      .then((response) => {
        setEditDialog(!editDialog);
        // console.log(response);
      })
      .catch((error) => console.log(error));
  };

  const toggleEditDialog = (course) => {
    
    if (!editDialog) {
      setCourse(course);
      setValue("price", course?.price);
      setValue("priceDiscount", course?.priceDiscount);

      getDomain((course?.id || 0));
    }
    
    
    setEditDialog(!editDialog);
  };

  if (courses === null) {
    return (
      <div className="w-full h-screen flex flex-row justify-center items-center">
        <span className="loader"></span>
      </div>
    );
  }

  return (
    <>
      <Layout>
        <div className="mx-auto">
          <table className="w-full border-collapse border border-slate-500 text-left">
            <thead>
              <tr>
                <th className="border border-slate-600 p-2">Corso</th>
                <th className="border border-slate-600 p-2">Titolo</th>
                <th className="border border-slate-600 p-2">Prezzo</th>
                <th className="border border-slate-600 p-2">Prezzo Scontato</th>
                <th className="border border-slate-600 p-2">Azioni</th>
              </tr>
            </thead>
            <tbody>
              {courses &&
                courses?.data?.map((course, index) => (
                  <tr className="even:bg-gray-70 odd:bg-white" key={index}>
                    <td className="border border-slate-700 p-2 py-4">
                      {course?.code}
                    </td>
                    <td className="border border-slate-700 p-2 py-4">
                      {course?.title}
                    </td>
                    <td className="border border-slate-700 p-2 py-4">
                      {parseFloat(course?.price).toFixed(2)} €
                    </td>
                    <td className="border border-slate-700 p-2 py-4">
                      {parseFloat(course?.priceDiscount).toFixed(2)} €
                    </td>
                    <td className="border border-slate-700 p-2 py-4">
                      <div
                        className="cursor-pointer"
                        onClick={() =>
                          toggleEditDialog({
                            token,
                            id: course?.id,
                            price: course?.price,
                            priceDiscount: course?.priceDiscount,
                          })
                        }
                      >
                        <FaRegEdit size={24} />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Layout>
      <Dialog open={editDialog} handler={toggleEditDialog}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogHeader className="flex-col">
            <div>Modifica Prezzo Corso</div>
            <div className="text-red-700 text-xs">IMPORTANTE: i prezzi devono essere caricati comprensivi di IVA, Se presente IVA configurata nella piattaforma sarà scorporata dove previsto.</div>
          </DialogHeader>
          <DialogBody divider>
            <p className="mb-2"><b>Prezzo generale:</b></p>
            <div className="w-full">
              <div className="md:grid grid-cols-2 gap-4">
                <div>
                  <Input
                    label="Prezzo"
                    type="text"
                    {...register("price", {
                      required: true,
                      pattern: /^[0-9]+([.][0-9]+)?$/,
                    })}
                  />
                  {errors?.price && (
                    <span className="text-red-900">Inserisci il prezzo</span>
                  )}
                </div>
                <div>
                  <Input
                    label="Prezzo scontato"
                    type="text"
                    {...register("priceDiscount", {
                      required: true,
                      pattern: /^[0-9]+([.][0-9]+)?$/,
                    })}
                  />
                  {errors?.priceDiscount && (
                    <span className="text-red-900">
                      Inserisci il prezzo scontato
                    </span>
                  )}
                </div>
              </div>
            </div>

            {
              domains?.data?.filter((domain) => { return domain.isActive === 1 }).map((domain, index) => {
                return (
                  <div className="mt-4 font-medium">
                    <p className="mb-2">dominio: <b>{domain.domain}</b></p>
                    <div className="w-full">
                      <div className="md:grid grid-cols-2 gap-4">
                        <div>
                          <Input
                            label="Prezzo"
                            type="text"
                            {...register(`price-${domain.id}`, {
                              required: true,
                              pattern: /^[0-9]+([.][0-9]+)?$/,
                            })}
                          />
                          {errors?.price && (
                            <span className="text-red-900">Inserisci il prezzo</span>
                          )}
                        </div>
                        <div>
                          <Input
                            label="Prezzo scontato"
                            type="text"
                            {...register(`priceDiscount-${domain.id}`, {
                              required: true,
                              pattern: /^[0-9]+([.][0-9]+)?$/,
                            })}
                          />
                          {errors?.priceDiscount && (
                            <span className="text-red-900">
                              Inserisci il prezzo scontato
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }

          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={() => toggleEditDialog()}
              className="mr-1"
            >
              <span>Annulla</span>
            </Button>
            <Button variant="gradient" color="green" type="submit">
              <span>Salva</span>
            </Button>
          </DialogFooter>
        </form>
      </Dialog>
    </>
  );
}
