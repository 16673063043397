import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { userService } from "../../api/user";

import {
  Input,
  Button
} from "@material-tailwind/react";

function PaymentForm(props) {
  const {
    setShowPayment,
    userData,
    setViewFormUserData,
    getUserData,
  } = props || null;

  const [userType, setUserType] = useState(null);

  // const [userData, setUserData] = useState(false);
  const token = localStorage.getItem("token");

  const getDayOfBirth = (date) => {
    return new Date(date).getDate();
  };

  const getMonthOfBirth = (date) => {
    return new Date(date).getMonth() + 1;
  };

  const getYearfBirth = (date) => {
    return new Date(date).getFullYear();
  };

  useEffect(() => {
    if (userData?.taxID) {
      setUserType("company");
    } else {
      setUserType("private");
    }
    
    // eslint-disable-next-line
  }, [setUserType]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userType: userData?.taxID ? "company" : "private",
      name: userData?.name ? userData?.name : "",
      surname: userData?.surname ? userData?.surname : "",
      dateOfBirth: userData?.dateOfBirth ? userData?.dateOfBirth : "",
      dayBirth: userData?.dateOfBirth
        ? getDayOfBirth(userData?.dateOfBirth)
        : "",
      monthBirth: userData?.dateOfBirth
        ? getMonthOfBirth(userData?.dateOfBirth)
        : "",
      yearBirth: userData?.dateOfBirth
        ? getYearfBirth(userData?.dateOfBirth)
        : "",
      birthCity: userData?.birthCity ? userData?.birthCity : "",
      birthProvince: userData?.birthProvince ? userData?.birthProvince : "",
      fiscalCode: userData?.fiscalCode ? userData?.fiscalCode : "",
      address: userData?.address ? userData?.address : "",
      houseNumber: userData?.houseNumber ? userData?.houseNumber : "",
      zipcode: userData?.zipcode ? userData?.zipcode : "",
      residenceCity: userData?.residenceCity ? userData?.residenceCity : "",
      residenceProvince: userData?.residenceProvince
        ? userData?.residenceProvince
        : "",
      companyName: userData?.companyName ? userData?.companyName : "",
      taxID: userData?.taxID ? userData?.taxID : "",
      uniqueCodeBilling: userData?.uniqueCodeBilling
        ? userData?.uniqueCodeBilling
        : "",
      pec: userData?.pec ? userData?.pec : "",
    },
  });

  const onSubmit = (data) => {
    const {
      name,
      surname,
      birthCity,
      birthProvince,
      fiscalCode,
      address,
      houseNumber,
      zipcode,
      residenceCity,
      residenceProvince,
      companyName,
      taxID,
      uniqueCodeBilling,
      pec,
    } = data || null;

    const dateOfBirth = data?.yearBirth + "-" + data?.monthBirth + "-" + data?.dayBirth;

    //-- token
    let params = {};

    if (userType === "private") {
      params = {
        token: token,
        name: name,
        surname: surname,
        dateOfBirth: dateOfBirth,
        birthCity: birthCity,
        birthProvince: birthProvince,
        fiscalCode: fiscalCode.toUpperCase(),
        address: address,
        houseNumber: houseNumber,
        zipcode: zipcode,
        residenceCity: residenceCity,
        residenceProvince: residenceProvince,
        companyName: "",
        taxID: "",
        uniqueCodeBilling: "",
        pec: "",
      };
    }

    if (userType === "company") {
      params = {
        token: token,
        name: "",
        surname: "",
        dateOfBirth: dateOfBirth,
        birthCity: "",
        birthProvince: "",
        fiscalCode: fiscalCode.toUpperCase(),
        address: address,
        houseNumber: houseNumber,
        zipcode: zipcode,
        residenceCity: residenceCity,
        residenceProvince: residenceProvince,
        companyName: companyName,
        taxID: taxID,
        uniqueCodeBilling: uniqueCodeBilling,
        pec: pec,
      };
    }
    userService
      .userPayment({ params })
      .then((response) => {
        setShowPayment(true);
        setViewFormUserData(false);
        getUserData();
      })
      .catch((error) => { });
  };

  const getYears = () => {
    const thisYear = new Date().getFullYear();
    const yearStartFrom = 1910;
    let yearsArr = [];
    for (let i = thisYear; i >= yearStartFrom; i--) {
      yearsArr.push(i);
    }
    return yearsArr;
  };

  const days = [...Array(31).keys()].map((x) => ++x);
  const months = [...Array(12).keys()].map((x) => ++x);
  const years = getYears();

  const handleUserType = (e) => {
    if (e.target.value) {
      setUserType(e.target.value);
    }
  };

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="flex flex-wrap">
            <input
              type="radio"
              id={1}
              name={"userTypePrivate"}
              label="Privato"
              {...register("userType")}
              value={"private"}
              onClick={(e) => handleUserType(e)}
            />
            <p className="ml-2">Persona fisica</p>
          </div>
        </div>
        <div>
          <div className="flex flex-wrap">
            <input
              type="radio"
              id={1}
              name={"userTypeCompany"}
              label="Azienda o P.iva"
              {...register("userType")}
              value={"company"}
              onClick={(e) => handleUserType(e)}
            />
            <p className="ml-2">Azienda</p>
          </div>
        </div>

        {userType === "private" && (
          <div className="mt-8">
            <p className="text-2xl">Inserisci i tuoi dati</p>
            <div className="mt-4 md:grid grid-cols-2 gap-4 w-full">
              <div>
                <p className="mb-1 font-semibold">Nome</p>
                <Input
                  label="Nome"
                  type="text"
                  {...register("name", { required: true })}
                />
                {errors.name && (
                  <span className="text-red-900">Inserisci il tuo nome</span>
                )}
              </div>
              <div>
                <p className="mb-1 font-semibold">Cognome</p>
                <Input
                  label="Cognome"
                  type="text"
                  {...register("surname", { required: true })}
                />
                {errors.surname && (
                  <span className="text-red-900">Inserisci il tuo cognome</span>
                )}
              </div>
            </div>
            <div className="mt-4 lg:grid grid-cols-2 gap-4 w-full mb-4 lg:mb-0">
              <div>
                <p className="mb-1 font-semibold">Data di nascita</p>
                <div className="flex flex-wrap gap-2">
                  <div>
                    <select {...register("dayBirth", { required: true })}>
                      {days?.map((item, index) => (
                        <option value={`${item}`} key={index}>
                          {item}
                        </option>
                      ))}
                    </select>

                    {errors.dayBirth && (
                      <span className="text-red-900">
                        Inserisci il tuo giorno di nascita
                      </span>
                    )}
                  </div>
                  <div>
                    <select
                      label="Mese"
                      {...register("monthBirth", { required: true })}
                    >
                      {months?.map((item, index) => (
                        <option value={`${item}`} key={index}>
                          {item}
                        </option>
                      ))}
                    </select>
                    {errors.monthBirth && (
                      <span className="text-red-900">
                        Inserisci il tuo mese di nascita
                      </span>
                    )}
                  </div>
                  <div>
                    <select
                      label="Anno"
                      {...register("yearBirth", { required: true })}
                    >
                      {years?.map((item, index) => (
                        <option value={`${item}`} key={index}>
                          {item}
                        </option>
                      ))}
                    </select>
                    {errors.yearBirth && (
                      <span className="text-red-900">
                        Inserisci il tuo anno di nascita
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="md:grid grid-cols-2 gap-2 mt-4 lg:mt-0">
                <div>
                  <p className="mb-1 font-semibold">Luogo di nascita</p>
                  <Input
                    label="Comune"
                    type="text"
                    {...register("birthCity", { required: true })}
                  />
                  {errors.birthCity && (
                    <span className="text-red-900">
                      Inserisci la tua città di nascita
                    </span>
                  )}
                </div>
                <div>
                  <p className="mb-1 font-semibold">Provincia</p>
                  <Input
                    label="Provincia"
                    type="text"
                    {...register("birthProvince", {
                      required: true,
                      maxLength: 2,
                    })}
                  />
                  {errors.birthProvince && (
                    <span className="text-red-900">
                      Insersci la tua provincia di nascita, solo due lettere
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-4 mb-8 md:grid grid-cols-2">
              <div>
                <p className="mb-1 font-semibold">Codice fiscale</p>
                <Input
                  label="Codice Fiscale"
                  type="text"
                  {...register("fiscalCode", { required: true })}
                />
                {errors.fiscalCode && (
                  <span className="text-red-900">
                    Inserisci il tuo codice fiscale
                  </span>
                )}
              </div>
            </div>
            <hr />
            <div className="mt-4">
              {/* <p className="mb-1 font-semibold">Residenza o domicilio</p> */}
              <div className="lg:grid grid-cols-2 gap-4">
                <div>
                  <p className="mb-1 font-semibold">Indirizzo di residenza</p>
                  <Input
                    label="Indirizzo"
                    type="text"
                    {...register("address", { required: true })}
                  />
                  {errors.address && (
                    <span className="text-red-900">
                      Inserisci il tuo indirizzo di residenza
                    </span>
                  )}
                </div>
                <div className="lg:grid grid-cols-2 gap-4 mt-4 lg:mt-0">
                  <div>
                    <p className="mb-1 font-semibold">Numero civico</p>
                    <Input
                      label="Numero civico"
                      type="text"
                      {...register("houseNumber", { required: true })}
                    />
                    {errors.houseNumber && (
                      <span className="text-red-900">
                        Inserisci il tuo numero civico
                      </span>
                    )}
                  </div>
                  <div className="mt-4 lg:mt-0">
                    <p className="mb-1 font-semibold">Codice postale</p>
                    <Input
                      label="Codice postale"
                      type="text"
                      {...register("zipcode", { required: true })}
                    />
                    {errors.zipcode && (
                      <span className="text-red-900">
                        Inserisci il codice postale
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="md:grid grid-cols-2 gap-4 mt-4">
                <div>
                  <p className="mb-1 font-semibold">Città</p>
                  <Input
                    label="Città"
                    type="text"
                    {...register("residenceCity", { required: true })}
                  />
                  {errors.residenceCity && (
                    <span className="text-red-900">
                      Inserisci la tua città di residenza
                    </span>
                  )}
                </div>
                <div className="md:grid grid-cols-2 gap-4">
                  <div>
                    <p className="mb-1 font-semibold">Provincia</p>
                    <Input
                      label="Provincia"
                      type="text"
                      {...register("residenceProvince", {
                        required: true,
                        maxLength: 2,
                      })}
                    />
                    {errors.residenceProvince && (
                      <span className="text-red-900">
                        Inserisci la tua provincia di residenza, solo due
                        lettere
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {userType === "company" && (
          <div className="mt-8">
            <p className="text-2xl">Inserisci i tuoi dati</p>
            <div className="mt-4 mb-8 md:grid grid-cols-2 gap-4 w-full">
              <div>
                <p className="mb-1 font-semibold">Ragione sociale</p>
                <Input
                  label="Ragione sociale"
                  type="text"
                  {...register("companyName", { required: true })}
                />
                {errors.companyName && (
                  <span className="text-red-900">
                    Inserisci la ragione sociale
                  </span>
                )}
              </div>
              <div>
                <p className="mb-1 font-semibold">Partita Iva</p>
                <Input
                  label="Partita iva"
                  type="text"
                  {...register("taxID", { required: true })}
                />
                {errors.taxID && (
                  <span className="text-red-900">Inserisci la partita iva</span>
                )}
              </div>             
              <div>
                <p className="mb-1 font-semibold">Codice fiscale</p>
                <Input
                  label="Codice Fiscale"
                  type="text"
                  {...register("fiscalCode", { required: false })}
                />
                {errors.fiscalCode && (
                  <span className="text-red-900">
                    Inserisci il tuo codice fiscale
                  </span>
                )}
              </div>
            </div>

            <hr />
            <div className="mt-4 mb-8">
              {/* <p className="mb-1 font-semibold">Indirizzo sede legale </p> */}
              <div className="lg:grid grid-cols-2 gap-4">
                <div>
                  <p className="mb-1 font-semibold">Indirizzo sede legale</p>
                  <Input
                    label="Indirizzo"
                    type="text"
                    {...register("address", { required: true })}
                  />
                  {errors.address && (
                    <span className="text-red-900">
                      Inserisci il tuo indirizzo di residenza
                    </span>
                  )}
                </div>
                <div className="md:grid grid-cols-2 gap-4">
                  <div className="mt-4 lg:mt-0">
                    <p className="mb-1 font-semibold">Numero civico</p>
                    <Input
                      label="Numero civico"
                      type="text"
                      {...register("houseNumber", { required: true })}
                    />
                    {errors.houseNumber && (
                      <span className="text-red-900">
                        Inserisci il tuo numero civico
                      </span>
                    )}
                  </div>
                  <div className="mt-4 lg:mt-0">
                    <p className="mb-1 font-semibold">Codice postale</p>
                    <Input
                      label="Codice postale"
                      type="text"
                      {...register("zipcode", { required: true })}
                    />
                    {errors.zipcode && (
                      <span className="text-red-900">
                        Inserisci il codice postale
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="md:grid grid-cols-2 gap-4 mt-4">
                <div>
                  <p className="mb-1 font-semibold">Città</p>
                  <Input
                    label="Città"
                    type="text"
                    {...register("residenceCity", { required: true })}
                  />
                  {errors.residenceCity && (
                    <span className="text-red-900">
                      Inserisci la tua città di residenza
                    </span>
                  )}
                </div>
                <div className="md:grid grid-cols-2 gap-4">
                  <div>
                    <p className="mb-1 font-semibold">Provincia</p>
                    <Input
                      label="Provincia"
                      type="text"
                      {...register("residenceProvince", { required: true })}
                    />
                    {errors.residenceProvince && (
                      <span className="text-red-900">
                        Inserisci la tua provincia di residenza
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="mt-4 mb-8">
              <div className="md:grid grid-cols-2 gap-4">
                <div>
                  <p className="mb-1 font-semibold">Codice univoco</p>
                  <Input
                    label="Codice univoco"
                    type="text"
                    {...register("uniqueCodeBilling")}
                  />
                  {errors.uniqueCodeBilling && (
                    <span className="text-red-900">
                      Inserisci il tuo codice univoco
                    </span>
                  )}
                </div>
                <div>
                  <p className="mb-1 font-semibold">PEC</p>
                  <Input
                    label="PEC"
                    type="email"
                    {...register("pec", { required: true })}
                  />
                  {errors.pec && (
                    <span className="text-red-900">
                      Inserisci il tuo numero civico
                    </span>
                  )}
                </div>
              </div>
              {/*<p className="mt-6">
                Il servizio di formazione è soggetto a ritenuta d'acconto del
                20%, il prezzo dell'attestato è di{" "}
                <span className="font-bold">euro 30,83</span>.
                  </p>*/}
            </div>
          </div>
        )}
        {userType && (
          <div className="flex flex-wrap justify-end mt-8 mb-8">
            <Button type="submit">Salva</Button>
          </div>
        )}
      </form>
    </div>
  );
}

export default PaymentForm;
