import { useState, useEffect } from "react";
import { adminService } from "../../../api/admin";

import Layout from "../../../components/layout";
import { Button } from "@material-tailwind/react";

export default function DomainsView() {
  const [domains, setDomains] = useState(null);

  let token = localStorage.getItem("token");

  const handleDomain = (id, freeCourse) => {
    let isFree = 0;
    if (freeCourse === 0) {
      isFree = 1;
    }

    const params = {
      token,
      idDomain: id,
      freeCourse: isFree,
    };

    adminService
      .updateDomain(params)
      .then((response) => {
        getDomain();
      })
      .catch((error) => console.log(error));
  };

  const getDomain = () => {
    adminService
      .getDomain({ token })
      .then((response) => {
        if (response?.data?.payload) {
          setDomains(response?.data?.payload);
        }
      })
      .catch((error) => {
        setDomains(false);
      });
  };

  useEffect(() => {
    getDomain();
    // eslint-disable-next-line
  }, []);

  if (domains === null) {
    return (
      <div className="w-full h-screen flex flex-row justify-center items-center">
        <span className="loader"></span>
      </div>
    );
  }

  return (
    <Layout>
      <div className="mx-auto">
        <table className="w-full border-collapse border border-slate-500 text-left">
          <thead>
            <tr>
              <th className="border border-slate-600 p-2">Dominio</th>
              <th className="border border-slate-600 p-2">Azione</th>
            </tr>
          </thead>
          <tbody>
            {domains?.data?.map((domain, index) => (
              <tr key={index}>
                <td className="border border-slate-700 p-2 py-4">
                  {domain?.domain}
                </td>
                <td className="border border-slate-700 p-2 py-4">
                  <Button
                    color={domain?.freeCourse ? "red" : "blue"}
                    onClick={() => handleDomain(domain?.id, domain?.freeCourse)}
                    className="mr-1"
                  >
                    {domain?.freeCourse === 1 ? "Disattivato" : "Attivato"}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
}
