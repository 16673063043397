import React from "react";
import Content from "./content";
import NavBar from "./navbar";

function Layout({ children }) {
  return (
    <>
      <div className="mx-auto container">
        <div className="sticky top-0 topbarMenu">
          <NavBar />
        </div>
        <div>
          <Content>{children}</Content>
        </div>
      </div>
    </>
  );
}

export default Layout;
