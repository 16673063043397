import { useNavigate } from "react-router-dom";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { adminService } from "../../api/admin";

const PayPalCheckoutTutor = (props) => {
  let navigate = useNavigate();

  //---

  return (
    <>
      <div className="m-0 p-0bg-white">
        <div className="mt-4">
          <PayPalButtons
            style={{
              color: "gold",
              height: 48,
              layout: "horizontal",
              tagline: "false",
              shape: "rect",
            }}
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    description: props.description,
                    amount: {
                      value: props.price,
                      currency: "EUR",
                    },
                  },
                ],
              });
            }}
            onApprove={async (data, actions) => {
              const ret = await actions.order.capture();
              if (ret.status !== "COMPLETED") {
                setTimeout(() => {
                  navigate("/wrongPayment-tutor");
                }, 1000);
              }
              //--- ---

              const params = {
                token: localStorage.getItem("token") || "",
                item: props.description,
                fileName: ret.id,
                method: 'paypal',
                credits: props.credits || 0,
              };
              adminService
                .cartInsert(params)
                .then(function (response) {
                  if (response?.data?.payload?.status === "success") {
                    setTimeout(() => {
                      navigate("/thankYouPayment-tutor");
                    }, 1000);
                  } else {
                    setTimeout(() => {
                      navigate("/wrongPayment-tutor");
                    }, 1000);
                  }
                })
                .catch(function (error) {
                  setTimeout(() => {
                    navigate("/wrongPayment-tutor");
                  }, 1000);
                });

              //--- --
            }}
            onError={(err) => {
              //setError(err);
              navigate("/wrongPayment-tutor");
            }}
            onCancel={() => {
              navigate("/wrongPayment-tutor");
            }}
          />
        </div>
      </div>
    </>
  );
};

export default PayPalCheckoutTutor;
