import { useState, useContext, useEffect } from "react";
import { UserContext } from "../../context/userContext";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { userService } from "../../api/user";

import { Input, Button } from "@material-tailwind/react";

import { LockClosedIcon } from "@heroicons/react/20/solid";
import { ROUTES } from "../../routing/routes";

function LoginForm({
  setUserToken,
  setToken,
  token,
  toggleDialogForgotPassword,
}) {
  const location = useLocation();
  const domainUrl = new URLSearchParams(location.search).get("domain");
  let domainFrom = process.env.REACT_APP_DOMAIN;

  if (domainUrl !== null) {
    domainFrom = domainUrl;
  }

  const [/*user*/, setUser] = useContext(UserContext);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState(false);

  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    // let domain = window.location.host;
    // let object = { ...data, domain };

    await loginUser({
      domain: domainFrom,
      email,
      password,
    });
  };

  useEffect(() => {
    loginByToken(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const loginByToken = (token) => {
    if (token === "" || token === false) {
      return true;
    }

    userService
      .getUser({ token })
      .then((response) => {
        const role = response?.data?.payload[0].role || "";
        setUser(response?.data?.payload[0]);
        setToken(token);
        setUserToken(true);
        setUser(response.data.payload);
        localStorage.setItem("token", token);
        localStorage.setItem("role", role);

        switch (role) {
          case "student":
            navigate(ROUTES.COURSES);
            break;
          case "admin":
            navigate("/admin/users/student");
            break;
          case "tutor":
            navigate(ROUTES.PRICELIST);
            break;
          default:
            break;
        }
      })
      .catch(function (error) {
        setError(error?.response?.data?.message);
      });
  };

  async function loginUser(credentials) {
    const params = {
      ...credentials,
    };

    userService
      .login(params)
      .then(function (response) {
        const token = response.data.payload.token;
        const role = response.data.payload.role;

        //TODO ripristinare dopo test

        /*if (role !== "admin" && response?.data?.payload?.domain !== "*") {
          if (domainUrl == null || domainUrl === null || domainUrl === "null") {
            setTimeout(() => {
              window.location.replace(
                `https://${response?.data?.payload?.domain}`
              );
            }, 500);
            return false;
          }

          if (response?.data?.payload?.domain !== domainUrl.replace("/", "")) {
            //alert('Accesso non autorizzato. Se il problema persiste contatta il servizio assistenza.');
            setTimeout(() => {
              window.location.replace(
                `https://${response?.data?.payload?.domain}`
              );
            }, 500);
            return false;
          }
        }*/

        setToken(token);
        setUserToken(true);
        setUser(response.data.payload);
        localStorage.setItem("token", token);
        localStorage.setItem("role", role);

        switch (role) {
          case "student":
            navigate(ROUTES.COURSES);
            break;
          case "admin":
            navigate("/admin/users/student");
            break;
          case "tutor":
            navigate(ROUTES.PRICELIST);
            break;
          default:
            break;
        }
      })
      .catch(function (error) {
        setError(error?.response?.data?.message);
      });
  }

  let registerLink = "/register?domain=" + domainFrom;

  return (
    <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8 border-2 rounded-3xl bg-white">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src={"/images/logo.svg"}
              alt="LOGO"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Login
            </h2>
          </div>
          {error && <p className="text-red-900 text-center">{error}</p>}
          <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="-space-y-px rounded-md shadow-sm">
              <div className="mb-6">
                <Input
                  label="Email"
                  type="email"
                  {...register("email", {
                    required: true,
                    pattern: /^\S+@\S+$/i,
                    onChange: (e) => setEmail(e.target.value),
                  })}
                />
                {errors.email && (
                  <span className="text-red-900">Inserisci la tua email</span>
                )}
              </div>
              <div className="mb-2">
                <Input
                  type="password"
                  label="Password"
                  {...register("password", {
                    required: true,
                    onChange: (e) => setPassword(e.target.value),
                  })}
                />
                {errors.password && (
                  <span className="text-red-900">
                    Inserisci la tua password
                  </span>
                )}
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="text-sm">
                <div
                  onClick={() => toggleDialogForgotPassword()}
                  // href="#"
                  className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer">
                  Hai dimenticato la password?
                </div>
              </div>
            </div>

            <div>
              <Button
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockClosedIcon
                    className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                    aria-hidden="true"
                  />
                </span>
                Login
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div className="mt-4 text-center text-white">
        <p>
          Non hai un account?{" "}
          <Link to={registerLink} className="ml-1 underline">
            Registrati
          </Link>
        </p>
      </div>
    </>
  );
}

export default LoginForm;

// LoginForm.propTypes = {
//   setToken: PropTypes.func.isRequired,
// };
