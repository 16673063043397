import React, {useState} from "react";

import RegisterForm from "../../components/registerForm";

import styles from "./style.module.scss";

import { Dialog, DialogHeader, DialogFooter, Button } from "@material-tailwind/react";
import PrivacyPolicy from "../../components/privacyPolicy";
import TermsAndConditions from "../../components/termsandconditions";

export default function RegisterView() {
  const [dialogPrivacy, setDialogPrivacy] = useState(false);
  const [dialogTerms, setDialogTerms] = useState(false);

  const toggleDialogPrivacy = () => {
    setDialogPrivacy(!dialogPrivacy);
  }

  const toggleDialogTerms = () => {
    setDialogTerms(!dialogTerms);
  }

  return (
    <div
      className={`${styles.container} flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gradient-to-b from-blue-800 to-blue-500`}
    >
      <div className="w-full">
        <RegisterForm toggleDialogPrivacy={toggleDialogPrivacy} toggleDialogTerms={toggleDialogTerms}/>
        <div className="text-center mt-4 text-white cursor-pointer" onClick={toggleDialogPrivacy}>Privacy Policy</div>
      </div>

      <Dialog open={dialogPrivacy} handler={toggleDialogPrivacy} size={"xxl"}>
        <DialogHeader>Privacy Policy</DialogHeader>
        
          <PrivacyPolicy />
          <DialogFooter>
            <Button onClick={toggleDialogPrivacy}>Chiudi</Button>
          </DialogFooter>
      </Dialog>

      <Dialog open={dialogTerms} handler={toggleDialogPrivacy} size={"xxl"}>
        {/* <DialogHeader>Privacy Policy</DialogHeader> */}
        
          <TermsAndConditions />
          <DialogFooter>
            <Button onClick={toggleDialogTerms}>Chiudi</Button>
          </DialogFooter>
      </Dialog>
    </div>
  );
}
