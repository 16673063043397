import { Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/layout";

export default function ThankYoutPayment() {
  let navigate = useNavigate();

  return (
    <Layout>
      <div className="container mx-auto">
        <div className="mt-4">
          <p className="text-2xl font-bold mb-4">
            Il pagamento è andato a buon fine.
          </p>
          <p>
            Grazie! Il pagamento è stato effettuato. <br />
            Il tuo attestato è in fase di verifica.
          </p>
          <div className="mt-4">
            <Button onClick={() => navigate("/certificates")}>
              Vedi i tuoi attestati
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
}
