import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  Alert,
} from "@material-tailwind/react";

import { quizService } from "../../../api/quiz";
import { getDomain } from "../../../utils/domain";

import Layout from "../../../components/layout";
import { userService } from "../../../api/user";
import { useEffect } from "react";

export default function QuizView() {
  const location = useLocation();
  const { course, quiz, registrationCode } = location?.state || null;
  const [dialogError, setDialogError] = useState(false);
  const [quizSuccess, setQuizSuccess] = useState(false);
  const [, /*quizDone*/ setQuizDone] = useState(false);
  const [dialogQuiz, setDialogQuiz] = useState(false);
  const [errorQuiz, setErrorQuiz] = useState(false);
  const [user, setUser] = useState();

  //-- token
  const token = localStorage.getItem("token");
  localStorage.setItem("quizType", quiz?.qType);

  //-- domain
  const domain = getDomain();

  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm();

  const submitQuiz = () => {
    let params = {
      ...quiz,
      token: token,
      domain: domain,
      registrationCode: registrationCode,
    };

    setQuizDone(true);

    quizService
      .saveAttempt({ params })
      .then((response) => {
        // if(response?.payload?.)

        if (response?.data?.payload?.outcome === "success") {
          setQuizSuccess(true);
          setDialogQuiz(true);
        } else {
          setQuizSuccess(false);
          setDialogQuiz(true);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 422) {
          setErrorQuiz(true);
        }
      });
  };

  const onSubmit = (data) => {
    // user answer
    const answer = Object.keys(data).length;

    // user right answer
    const rightAnswer = Object.keys(data).filter(
      (key) => data[key] === "1"
    ).length;

    // total question
    const questions = quiz?.questions?.length;

    const emptyAnswer = Object.keys(data).filter(
      (key) => data[key] === null
    ).length;

    if (emptyAnswer > 0) {
      setDialogError(true);
    } else {
      submitQuiz();
    }
  };

  const toggleDialogError = () => {
    setDialogError(!dialogError);
  };

  const toggleDialogQuiz = () => {
    setDialogQuiz(!dialogQuiz);
  };

  const toggleDialogErrorQuiz = () => {
    setErrorQuiz(!errorQuiz);
  };

  const setAnswer = (evt, question, answer) => {
    quiz.questions[question].answers.forEach(function (key) {
      key.userAnswer = 0;
    });
    quiz.questions[question].answers[answer].userAnswer = 1;
  };

  const navigateToCourse = () => {
    if (!quizSuccess) {
      navigate("/courses/course", { state: { course: course?.code } });
    } else {
      if (quiz?.qType === "testIntermedio") {
        navigate("/courses/course", { state: { course: course?.code } });
      } else {
        navigate("/payment", { state: { course: course } });
      }
    }
  };

  const getUser = () => {
    userService
      .getUser({ token })
      .then((response) => {
        setUser(response?.data?.payload[0]);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  //--- ---

  if (
    quizSuccess &&
    quiz?.qType === "testFinale" &&
    parseInt(user?.idParent) > 0
  ) {
    navigate("/payment", { state: { course: course } });
  }

  return (
    <Layout>
      <div className="container mx-auto relative mt-8">
        <h1 className="text-2xl font-bold">{quiz?.title}</h1>
        <p>{course?.title}</p>
        <div className="relative">
          <form onSubmit={handleSubmit(onSubmit)} className="block">
            <div>
              {quiz?.questions &&
                quiz?.questions?.map((item, index) => (
                  <div className="mt-4 bg-white p-4" key={index}>
                    <p className="text-lg font-semibold flex flex-row items-center">
                      <span>{item?.title}</span>
                    </p>
                    <div className="panel mt-8 flex flex-col">
                      {item?.answers &&
                        item?.answers?.map((answer, k) => (
                          <div key={k} className="flex">
                            <div className="mt-1">
                              <input
                                type="radio"
                                id={`answer-${k}`}
                                name={`answer-${k}`}
                                label={answer?.title}
                                {...register(`answer-${index + 1}`)}
                                value={answer?.outcome}
                                onClick={(e) => setAnswer(e, index, k)}
                              />
                            </div>
                            <div>
                              <p className="ml-2">{answer?.title}</p>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
            </div>
            <div className="flex flex-wrap justify-end mt-4">
              <Button type="submit">Conferma</Button>
            </div>
          </form>
        </div>
      </div>

      <Dialog open={dialogQuiz} handler={toggleDialogQuiz} size="lg">
        <DialogBody>
          {!quizSuccess && (
            <Alert color="red" className="text-center font-bold">
              Quiz non superato. Non hai risposto correttamente a tutte le
              domande
            </Alert>
          )}

          {quizSuccess && quiz?.qType === "testIntermedio" && (
            <Alert color="green" className="text-center font-bold">
              Quiz superato
            </Alert>
          )}

          {quizSuccess &&
            quiz?.qType === "testFinale" &&
            parseInt(user?.idParent) === 0 && (
              <Alert color="green" className="text-center font-bold">
                Quiz superato
              </Alert>
            )}

          {quizSuccess &&
            quiz?.qType === "testFinale" &&
            parseInt(user?.idParent) > 0 && (
              <Alert color="green" className="text-center font-bold">
                Complimenti. Hai superato il Test FInale. Contatta il tuo Tutor
                per il rilascio dell'attestato.
              </Alert>
            )}
        </DialogBody>
        <DialogFooter>
          <Button
            color="blue"
            className="mr-1"
            onClick={() => navigateToCourse()}>
            Torna al corso
          </Button>
        </DialogFooter>
      </Dialog>

      <Dialog open={dialogError} handler={toggleDialogError} size="lg">
        <DialogBody>
          <Alert color="amber" className="text-center font-bold">
            Rispondi a tutte le domande per confermare il quiz.
          </Alert>
        </DialogBody>
      </Dialog>

      <Dialog open={errorQuiz} handler={toggleDialogErrorQuiz} size="lg">
        <DialogBody>
          <Alert color="red" className="text-center font-bold">
            Hai eseguito questo test più delle volte consentite, le lezioni del
            modulo corrente saranno resettate e dovrai ripeterle.
          </Alert>
        </DialogBody>
        <DialogFooter>
          <Button
            color="blue"
            className="mr-1"
            onClick={() => navigateToCourse()}>
            Torna al corso
          </Button>
        </DialogFooter>
      </Dialog>
    </Layout>
  );
}
