export function formatDate(date) {

  if (date === null) { return true }

  var datePart = date.match(/\d+/g),
    // year = datePart[0].substring(2), // get only two digits
    year = datePart[0], // get only two digits
    month = datePart[1],
    day = datePart[2];

  return day + "/" + month + "/" + year;
}

export function hoursBetweenDate() { }


export function generaCodice(prefix = '00') {
  let data = new Date();
  let codice = '';

  codice += data.getFullYear().toString().slice(-2); // Aggiunge l'anno (ultimi due cifre)
  codice += (data.getMonth() + 1).toString().padStart(2, '0'); // Aggiunge il mese
  codice += data.getDate().toString().padStart(2, '0'); // Aggiunge il giorno
  codice += data.getHours().toString().padStart(2, '0'); // Aggiunge l'ora
  codice += data.getMinutes().toString().padStart(2, '0'); // Aggiunge i minuti
  codice += data.getSeconds().toString().padStart(2, '0'); // Aggiunge i secondi
  codice += data.getMilliseconds().toString().padStart(3, '0'); // Aggiunge i millisecondi

  return prefix+'-'+codice;
}