import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";

// assets
import Logo from "../../assets/logo.svg";

import { Input, Button, Checkbox } from "@material-tailwind/react";

function RegisterForm(props) {
  const {toggleDialogPrivacy, toggleDialogTerms} = props;
  const location = useLocation();
  const domainUrl = new URLSearchParams(location.search).get("domain");
  let domainFrom = process.env.REACT_APP_DOMAIN;

  if (domainUrl !== null) {
    domainFrom = domainUrl;
  }

  const [isRegistered, setIsRegister] = useState(false);
  const [error, setError] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    let role = "student";
    let domain = domainFrom;
    let object = { ...data, domain, role };

    let config = {
      method: "post",
      url: "https://app.labmar.it/backend/v1/account/register",
      headers: {
        "Content-Type": "application/json",
      },
      data: object,
    };

    axios(config)
      .then((response) => {
        setIsRegister(true);
      })
      .catch((error) => {
        setError(error?.response?.data?.message);
      });
  };

  let loginLink = "/login?domain=" + domainFrom;

  return (
    <>
      <div className="flex min-h-full items-center justify-center border-2 rounded-3xl bg-white py-8">
        {!isRegistered ? (
          <div className="w-full md:max-w-3xl">
            <div>
              <img className="mx-auto h-12 w-auto" src={Logo} alt="LOGO" />
              <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                Crea il tuo account
              </h2>
            </div>
            <form className="p-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-4 mb-2">
                <h3 className="font-bold">Inserisci i tuoi dati</h3>
              </div>
              <div className="md:border-2 rounded-3xl md:p-6 ">
                <div className="md:grid grid-cols-2 gap-4">
                  <div className="mb-4 md:mb-6">
                    <Input
                      label="Nome"
                      type="text"
                      {...register("name", { required: true })}
                    />
                    {errors.name && (
                      <span className="text-red-900">
                        Inserisci il tuo nome
                      </span>
                    )}
                  </div>
                  <div className="mb-4 md:mb-6">
                    <Input
                      label="Cognome"
                      type="text"
                      {...register("surname", { required: true })}
                    />
                    {errors.surname && (
                      <span className="text-red-900">
                        Inserisci il tuo cognome
                      </span>
                    )}
                  </div>
                </div>
                <div className="md:grid grid-cols-2 gap-4">
                  <div className="mb-4 md:mb-6">
                    <Input
                      label="Email"
                      type="email"
                      {...register("email", {
                        required: true,
                        pattern: /^\S+@\S+$/i,
                      })}
                    />
                    {errors.email && (
                      <span className="text-red-900">
                        Inserisci la tua email
                      </span>
                    )}
                  </div>
                  <div className="mb-4 md:mb-6">
                    <Input
                      type="password"
                      label="Password"
                      {...register("password", {
                        required: true,
                      })}
                    />
                    {errors.password && (
                      <span className="text-red-900">
                        Inserisci la tua password
                      </span>
                    )}
                  </div>
                </div>
                <div className="md:grid grid-cols-2 gap-4 mb-6">
                  <div>
                    <Input
                      label="Cellulare"
                      type="text"
                      {...register("phoneMobile", { required: true })}
                    />
                    {errors.phoneMobile && (
                      <span className="text-red-900">
                        Inserisci il tuo numero di telefono
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {error && <p className="text-red-900 text-center">{error}</p>}
              <div className="md:mt-8">
                <h3>Hai un buono sconto?</h3>
                <div>
                  <Input
                    label="Inserisci buono sconto"
                    type="text"
                    {...register("bonus")}
                  />
                </div>
              </div>
              <div className="flex flex-wrap items-center mt-4">
                  <Checkbox
                    color="green"
                    {...register("accepted", { required: true })}
                  />
                  <p>
                    Dichiaro di aver letto la <span className="font-bold underline cursor-pointer" onClick={toggleDialogPrivacy}>Privacy Policy</span> ai sensi del Regolamento UE 2016/679 (GDPR) e le <span className="font-bold underline cursor-pointer" onClick={toggleDialogTerms}>condizioni generali di utilizzo del servizio</span>
                    {errors.accepted && (
                      <p className="text-red-900">Conferma per continuare</p>
                    )}
                  </p>
                </div>
              <div className="mt-8 flex justify-end">
                <div>
                <Button
                  type="submit"
                  className="group relative flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Registrati
                </Button>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="w-full md:max-w-3xl">
            <p className="text-center">
              La tua registrazione è andata a buon fine. clicca su {" "}
              <Link to={loginLink} className="underline">Accedi</Link>
            </p>
          </div>
        )}
      </div>
      <div className="p-4 text-center text-white">
        <p>
          Hai già un account?{" "}
          <Link to={loginLink} className="ml-1 underline">
            Accedi
          </Link>
        </p>
      </div>
    </>
  );
}

export default RegisterForm;
