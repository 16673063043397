import styles from "./style.module.scss";

import { useEffect, useRef, useState } from "react";
import Layout from "../../../components/layout";
import { adminService } from "../../../api/admin";
import { useLocation } from "react-router-dom";

export default function CoursesAdminViewOnAir() {
  const [users, setUsers] = useState();
  const [pagination, setPagination] = useState(0);
  const [pages, setPages] = useState(null);
  const [forceReload, setForceReload] = useState(false);

  let token = localStorage.getItem("token");
  let numberPerPage = 200;

  const defaultSearch = useRef();
  const { pathname } = useLocation();

  //---
  const nextPage = () => {
    setPagination(pagination + 1);
  };

  const prevPage = () => {
    setPagination(pagination - 1);
  };

  let arr = [];

  const getPagination = () => {
    if (pages) {
      for (let i = 1; i <= pages; i++) {
        arr.push(i);
      }
      return arr;
    }
  };

  const goToPage = (item) => {
    setPagination(item - 1);
  };

  const goToSearch = () => {
    //if(defaultSearch.current.value === ''){ return true; }
    loadUserCourseStatus(defaultSearch.current.value);
    defaultSearch.current.value = "";
  }; //goToSearch

  const loadUserCourseStatus = (defaultSearch = "") => {
    let filterByPath = "";
    if (pathname === "/admin/course-onair") {
      filterByPath = "admin";
    }
    if (pathname === "/admin/course-onair-tutor") {
      filterByPath = "tutor";
    }

    let params = {
      token: token,
      page: pagination,
      defaultSearch: defaultSearch,
      filterByPath: filterByPath,
    };

    adminService
      .getUserCourseStatus(params)
      .then((response) => {
        setUsers(response?.data?.payload?.data);
        if (response?.data?.payload?.count) {
          setPages(Math.ceil(response?.data?.payload?.count / numberPerPage));
          setForceReload(!forceReload);
        }
      })
      .catch((error) => {
        setUsers([]);
        setPages(null);
      });
  }; //loadUsers

  //---

  useEffect(() => {
    loadUserCourseStatus();
    // eslint-disable-next-line
  }, [pagination, pathname]);

  useEffect(() => {
    loadUserCourseStatus();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <div className="mx-auto">
          <div className="m-4 text-sm">
            <p className="font-bold">Legenda:</p>
            <ul className="list-disc">
              <li className="inline-block">
                25% (prima prova intermedia superata) -{" "}
              </li>
              <li className="inline-block">
                50% (seconda prova intermedia superata) -{" "}
              </li>
              <li className="inline-block">100% (prova finale superata)</li>
            </ul>
          </div>

          <div>
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
              Search
            </label>
            <div className="relative mb-4">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                ref={defaultSearch}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    goToSearch();
                  }
                }}
                className="block w-full p-4 pl-10 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="cognome"
              />
              <button
                type="submit"
                onClick={(e) => {
                  goToSearch();
                }}
                className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 uppercase">
                cerca
              </button>
            </div>
          </div>

          <table className="w-full border-collapse border border-slate-500 text-left">
            <thead>
              <tr>
                <th className="border border-slate-600 p-2">Nome</th>
                <th className="border border-slate-600 p-2 ">Cognome</th>
                <th className="border border-slate-600 p-2 ">Corso</th>
                <th className="border border-slate-600 p-2 ">Avanzamento</th>
              </tr>
            </thead>
            <tbody>
              {users?.map((item, index) => (
                <tr key={index} className="even:bg-gray-70 odd:bg-white">
                  <td className="border border-slate-700 p-2 py-4 ">
                    {" "}
                    {item?.name}{" "}
                  </td>
                  <td className="border border-slate-700 p-2 py-4 ">
                    {" "}
                    {item?.surname}{" "}
                  </td>
                  <td className="border border-slate-700 p-2 py-4 ">
                    {" "}
                    {item?.courseTitle}{" "}
                  </td>
                  <td className="border border-slate-700 p-2 py-4 ">
                    {" "}
                    {item?.percentage}%{" "}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className={`${styles.pagination} flex flex-wrap justify-center`}>
            {pages !== null && (
              <div className="cursor-pointer" onClick={() => prevPage()}>
                Indietro
              </div>
            )}

            {getPagination()?.map((item, index) => (
              <div
                className={`${styles.pageNumber} ${
                  pagination === index ? styles.pageActive : ""
                }`}
                onClick={() => goToPage(item)}
                key={index}>
                {item}
              </div>
            ))}

            {pages !== null && (
              <div className="cursor-pointer" onClick={() => nextPage()}>
                Avanti
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
}
