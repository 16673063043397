import { Button } from "@material-tailwind/react";
import PayPalCheckoutTutor from "../../../components/paypalCheckoutTutor";
import { useMemo } from "react";

export default function PaymentButton(props) {
  const memoIdx = useMemo(() => props.idx, [props.idx]);
  const memoTitle = useMemo(() => props.item.title, [props.item.title]);
  const memoPriceCad = useMemo(() => props.item.priceCad, [props.item.priceCad]);
  const memoAttestati = useMemo(() => props.item.attestati, [props.item.attestati]);
  const memoAItemIdx = useMemo(() => props.itemIdx, [props.itemIdx]);

  return (
    <>
      <div className="flex justify-center items-baseline flex-wrap mt-8 mb-2">
        <Button
          className="w-full content-center text-1xl"
          onClick={(e) => {
            props.buyNow(e, memoIdx);
          }}>
          ACQUISTA CON BONIFICO
          {memoAItemIdx === memoIdx ? (
            <p className="text-xs">segui le indicazioni in basso</p>
          ) : (
            ""
          )}
        </Button>

        <div className="mt-4 mb-2 text-sm w-full">oppure utilizza</div>

        {/*<iframe src={`/paypalframe?key=&description&price=credits=`} allowFullScreen></iframe>*/}

        {<PayPalCheckoutTutor
          key={memoTitle}
          description={memoTitle}
          price={parseFloat(memoPriceCad * memoAttestati)}
          credits={parseInt(memoAttestati)}
          />}

        <div className="mt-0 mb-4 text-sm w-full">
          puoi pagare in 3 rate senza interessi
        </div>
      </div>
    </>
  );
}
