import { proxyBE } from "./common";

export const userService = {
  login(data) {
    return proxyBE.get("account/login", { params: data });
  },
  recoveryPassword(data) {
    const params = { email: data?.email };
    return proxyBE.get("account/recoveryPassword", { params });
  },
  validToken(data) {
    const params = { token: data.session };
    return proxyBE.get("account/checkToken", { params });
  },
  getUser(data) {
    const params = { token: data?.token };
    return proxyBE.get("account/user", { params });
  },
  userCertification(data) {
    const params = { ...data.params };
    return proxyBE.post("userCertification/saveUserDataCertification", params);
  },
  updateUserCertification(data) {
    const params = { ...data.params };
    return proxyBE.put("userCertification/updateUserDataCertification", params);
  },
  setPayed(data) {
    const params = { ...data.params };
    return proxyBE.post("userCertification/setPayed", params);
  },
  getUserAllCertification(data) {
    const params = { token: data.token };
    return proxyBE.get("userCertification/getUserAllCertification", { params });
  },
  userPayment(data) {
    const params = { ...data?.params };
    return proxyBE.post("account/personaldata", params);
  },
  uploadPayment(data) {
    const params = { ...data?.params };
    return proxyBE.post("userCourse/uploadpayment", params);
  },
  uploadPaymentTutor(data) {
    const params = { ...data?.params };
    return proxyBE.post("dashboard/tutor/uploadpayment", params);
  },
  getPersonalData(data) {
    const params = { ...data.params };
    return proxyBE.get("account/user", { params });
  },
  logout(data) {
    const params = { ...data?.params };
    return proxyBE.post("account/logout", params);
  },
};
