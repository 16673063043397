import { useState, useEffect } from "react";

import { FaTrashAlt } from "react-icons/fa";

import { useForm } from "react-hook-form";

import Layout from "../../../components/layout";

import { adminService } from "../../../api/admin";

import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
} from "@material-tailwind/react";

export default function CouponsView() {
  let token = localStorage.getItem("token");
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [couponToDelete, setCouponToDelete] = useState(null);

  const [coupons, setCoupons] = useState(false);

  const handleOpenDialog = () => setOpenDialog(!openDialog);

  const getCoupons = () => {
    adminService
      .getCoupons({ token })
      .then((response) => {
        if (response?.data?.payload) {
          setCoupons(response?.data?.payload);
        }
      })
      .catch((error) => {
        setCoupons(false);
      });
  };

  useEffect(() => {
    getCoupons();
    // eslint-disable-next-line
  }, [setCoupons]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    adminService
      .addCoupon({
        token,
        code: data?.coupon,
        amount: data?.amount,
      })
      .then((response) => {
        getCoupons();
        setOpenDialog(false);
      });
  };

  const toggleDeleteDialog = (coupon) => {
    setCouponToDelete(coupon);
    setDeleteDialog(!deleteDialog);
  };

  const handleDeleteCoupon = () => {
    adminService
      .deleteCoupon({
        token,
        id: couponToDelete,
      })
      .then((response) => {
        toggleDeleteDialog(false);
        getCoupons();
      });
  };

  if (coupons === null) {
    return (
      <div className="w-full h-screen flex flex-row justify-center items-center">
        <span className="loader"></span>
      </div>
    );
  }

  return (
    <>
      <Layout>
        <div className="mx-auto">
          <div className="flex flex-wrap justify-end mb-8">
            <Button onClick={handleOpenDialog} variant="gradient">
              Aggiungi nuovo coupon
            </Button>
          </div>
          <table className="w-full border-collapse border border-slate-500 text-left">
            <thead>
              <tr>
                <th className="border border-slate-600 p-2">Coupon</th>
                <th className="border border-slate-600 p-2">Valore</th>
                <th className="border border-slate-600 p-2">Azioni</th>
              </tr>
            </thead>
            <tbody>
              {coupons &&
                coupons.data?.map((coupon, index) => (
                  <tr className="even:bg-gray-70 odd:bg-white" key={index}>
                    <td className="border border-slate-700 p-2 py-4">
                      {coupon.code}
                    </td>
                    <td className="border border-slate-700 p-2 py-4">
                      {parseFloat(coupon.amount).toFixed(2)} €
                    </td>
                    <td className="border border-slate-700 p-2 py-4">
                      <div
                        className="cursor-pointer"
                        onClick={() => toggleDeleteDialog(coupon?.id)}>
                        <FaTrashAlt size={24} />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Layout>

      {/* DIALOG DELETE */}
      <Dialog open={deleteDialog} handler={toggleDeleteDialog}>
        <DialogHeader>Sei sicuro di voler eliminare il coupon?</DialogHeader>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => toggleDeleteDialog()}
            className="mr-1">
            <span>Annulla</span>
          </Button>
          <Button
            variant="gradient"
            color="red"
            onClick={() => handleDeleteCoupon()}>
            <span>elimina</span>
          </Button>
        </DialogFooter>
      </Dialog>

      {/* DIALOG INSERT */}
      <Dialog open={openDialog} handler={() => handleOpenDialog()}>
        <DialogHeader>Aggiungi nuovo codice coupon.</DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogBody divider>
            <Input
              label="Coupon"
              type="text"
              className="my-1"
              {...register("coupon", { required: true })}
            />
            <Input
              label="Sconto"
              type="text"
              className="my-1"
              {...register("amount", { required: true })}
            />
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={handleOpenDialog}
              className="mr-1">
              <span>Annulla</span>
            </Button>
            <Button
              variant="gradient"
              color="green"
              type="submit"
              // onClick={() => handleOpenDialog()}
            >
              <span>Salva</span>
            </Button>
          </DialogFooter>
        </form>
      </Dialog>
    </>
  );
}
