import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";

// Registra i font con gli URL diretti per i vari pesi
Font.register({
  family: "Roboto",
  fonts: [
    {
      src: `${process.env.PUBLIC_URL}/fonts/Roboto-Regular.ttf`,
      fontWeight: "normal",
    },
    {
      src: `${process.env.PUBLIC_URL}/fonts/Roboto-Bold.ttf`,
      fontWeight: "bold",
    },
  ],
});

// Crea gli stili
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 30,
  },
  header: {
    marginBottom: 10,
    textAlign: "center",
    width: "100%",
    fontSize: "9px",
    fontWeight: "normal",
  },
  fontWeightBold: {
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  grid: {
    display: "flex",
    flexDirection: "column",
    marginTop: 0,
  },
  row: {
    margin: 0,
    display: "flex",
    flexDirection: "row",
  },
  cell: {
    flex: 1,
    margin: 0,
    border: "1px solid black",
    padding: 3,
    textAlign: "center",
    fontSize: 9,
    justifyContent: "center", // Centra verticalmente il contenuto
  },
  cellHeader: {
    flex: 1,
    margin: 0,
    border: "1px solid black",
    padding: 3,
    textAlign: "center",
    fontSize: 9,
    fontFamily: "Roboto",
    fontWeight: "bold",
    backgroundColor: "#f0f0f0",
    justifyContent: "center", // Centra verticalmente il contenuto
  },
  boldCell: {
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  cellCol1: { flexBasis: "17%" },
  cellCol2: { flexBasis: "21%" },
  cellCol3: { flexBasis: "21.5%" },
  cellCol4: { flexBasis: "10.5%" },
  cellCol5: { flexBasis: "15%" },
  cellCol6: { flexBasis: "9.5%" },
  cellCol7: { flexBasis: "9.5%" },
});

// Crea il componente Document
const PdfCreate = (props) => {

  let { data, courseType, currentDate, protocolloAA } = props || {};

  // Crea le righe e le celle della griglia
  const renderGrid = () => {
    const rows = [];
    const headerData = {
      name: "NOME",
      surname: "COGNOME",
      birthCity: "LUOGO DI\nNASCITA",
      dateOfBirth: "DATA DI\nNASCITA",
      fiscalCode: "CODICE FISCALE",
      releasedAt: "DATA\nESAME FINALE",
      progress_aa: "ATTESTATO N.",
    };

    data = [headerData, ...data];

    for (let i = 0; i < data.length; i++) {
      const cells = [];

      //--- ---
      cells.push(
        <View
          style={[styles.cell, styles.cellCol1, i === 0 && styles.cellHeader]}>
          <Text>{data[i].name}</Text>
        </View>
      );

      cells.push(
        <View
          style={[styles.cell, styles.cellCol2, i === 0 && styles.cellHeader]}>
          <Text>{data[i].surname}</Text>
        </View>
      );

      cells.push(
        <View
          style={[styles.cell, styles.cellCol3, i === 0 && styles.cellHeader]}>
          <Text>{data[i].birthCity}</Text>
        </View>
      );

      cells.push(
        <View
          style={[styles.cell, styles.cellCol4, i === 0 && styles.cellHeader]}>
          <Text>
            {moment(data[i].dateOfBirth).format("DD/MM/YYYY") !== "Invalid date"
              ? moment(data[i].dateOfBirth).format("DD/MM/YYYY")
              : data[i].dateOfBirth}
          </Text>
        </View>
      );

      cells.push(
        <View
          style={[styles.cell, styles.cellCol5, i === 0 && styles.cellHeader]}>
          <Text>{data[i].fiscalCode}</Text>
        </View>
      );

      cells.push(
        <View
          style={[styles.cell, styles.cellCol6, i === 0 && styles.cellHeader]}>
          <Text>
            {moment(data[i].releasedAt).format("DD/MM/YYYY") !== "Invalid date"
              ? moment(data[i].releasedAt).format("DD/MM/YYYY")
              : data[i].releasedAt}
          </Text>
        </View>
      );

      cells.push(
        <View
          style={[styles.cell, styles.cellCol7, i === 0 && styles.cellHeader]}>
          <Text>{data[i].progress_aa}</Text>
        </View>
      );
      //--- ---

      rows.push(
        <View style={styles.row} key={`row-${i}`}>
          {cells}
        </View>
      );
    }
    return rows;
  };

  //--- ---

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.fontWeightBold}>
            ELENCO PERSONALE ALIMENTARISTA CHE HA FREQUENTATO CON PROFITTO IL
            CORSO DI FORMAZIONE ORGANIZZATO DA
          </Text>
          <Text style={{ marginTop: "3px" }}>
            denominazione ente:{" "}
            <Text style={styles.fontWeightBold}>
              LAB.MAR DEL DOTT.CALOGERO CECATO
            </Text>
          </Text>
          <Text style={{ marginTop: "3px" }}>
            iscritto nel registro regionale per la formazione degli
            alimentaristi con al n.2021/356 del 24/06/2021
          </Text>
          <Text style={{ marginTop: "3px" }}>
            comunicazione di attivazione corso inviata in data {protocolloAA || "____/____/____"}
          </Text>
          <Text style={{ marginTop: "3px" }}>
            durata del corso:{" "}
            <Text style={styles.fontWeightBold}>
              {courseType === "haccp6" ? "6 ore" : "12 ore"}
            </Text>
          </Text>
          <Text style={styles.fontWeightBold}>
            IN MODALITA' FAD ASINCRONA (FORMAZIONE A DISTANZA)
          </Text>
        </View>

        <View style={styles.grid}>{renderGrid()}</View>
      </Page>
    </Document>
  );
};

export default PdfCreate;
