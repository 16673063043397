import styles from "./style.module.scss";

import { useState, useEffect, useRef } from "react";

import { useForm } from "react-hook-form";

import {
  FaGift,
  FaRegFileAlt,
  FaTrashAlt,
  FaUserPlus,
  FaUsers,
} from "react-icons/fa";

import Layout from "../../../components/layout";

import { adminService } from "../../../api/admin";

import {
  Button,
  Dialog,
  DialogHeader,
  DialogFooter,
  Input,
  DialogBody,
} from "@material-tailwind/react";
import { Link, useParams } from "react-router-dom";
import useDashboardStore from "../../../zustandStore/dashboardStore";
import AccountDetailView from "../../accountDetail";

export default function UsersView() {
  const [users, setUsers] = useState();
  const [pagination, setPagination] = useState(0);
  const [pages, setPages] = useState(null);
  const [dialogDelete, setDialogDelete] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [OpenRegTutorDialog, setOpenRegTutorDialog] = useState(false);
  const [OpenRegStudentDialog, setOpenRegStudentDialog] = useState(false);
  const [OpenInfoAccountDialog, setOpenInfoAccountDialog] = useState(false);
  const [error, setError] = useState(null);
  const [forceReload, setForceReload] = useState(false);
  const [domains, setDomains] = useState(null);
  const [userIdDetail, setUserIdDetail] = useState(0);
  const dashboardStore = useDashboardStore((state) => {
    return state;
  });

  const { role, idParent } = useParams();
  const defaultSearch = useRef();

  let token = localStorage.getItem("token");
  let numberPerPage = 200;

  const handleRegTutorDialog = () => setOpenRegTutorDialog(!OpenRegTutorDialog);
  const handleRegStudentDialog = () =>
    setOpenRegStudentDialog(!OpenRegStudentDialog);
  const handleInfoAccountDialog = (userId) => {
    setOpenInfoAccountDialog(!OpenInfoAccountDialog);
    setUserIdDetail(userId);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState,
    formState: { errors /*, isSubmitSuccessful*/ },
  } = useForm();

  const onSubmitTutor = async (data) => {
    await registerTutor({
      name: data.name,
      surname: data.surname,
      email: data.email,
      password: data.password,
      phoneMobile: data.phoneMobile,
      domain: data.domain,
      token: localStorage.getItem("token"),
    });
  };

  const onSubmitStudent = async (data) => {
    await registerStudent({
      name: data.name,
      surname: data.surname,
      email: data.email,
      password: data.password,
      phoneMobile: data.phoneMobile,
      //birthCity: data.birthCity,
      //dateofBirth: data?.yearBirth + "-" + data?.monthBirth + "-" + data?.dayBirth,
      //fiscalCode: data.fiscalCode,
      token: localStorage.getItem("token"),
    });
  };

  const getCredits = () => {
    adminService
      .getCredits({ token: localStorage.getItem("token") || "" })
      .then((response) => {
        if (response?.data?.payload) {
          dashboardStore.dashboard.credits =
            response?.data?.payload.data[0].credit || 0;
          useDashboardStore.setState({ ...dashboardStore });
        }
      })
      .catch((error) => {});
  };

  const getDomain = () => {
    adminService
      .getDomain({ token })
      .then((response) => {
        if (response?.data?.payload) {
          setDomains(response?.data?.payload);
        }
      })
      .catch((error) => {
        setDomains(false);
      });
  };

  //---

  const formatDate = (date) => {
    if (date === null) {
      return true;
    }
    const dataString = date;
    const data = new Date(dataString);
    const day = String(data.getDate()).padStart(2, "0");
    const month = String(data.getMonth() + 1).padStart(2, "0");
    const year = String(data.getFullYear());
    return `${day}/${month}/${year}`;
  };

  //---

  async function registerTutor(credentials) {
    const params = {
      ...credentials,
    };
    adminService
      .registerTutor(params)
      .then(function (response) {
        if (response?.data?.payload?.status === "success") {
          setOpenRegTutorDialog(false);
          setForceReload(true);
          setTimeout(() => {
            setForceReload(false);
          }, 500);
        }
      })
      .catch(function (error) {
        setError(error?.response?.data?.message);
        setTimeout(() => {
          setError(null);
        }, 4000);
      });
  }

  async function registerStudent(credentials) {
    const params = {
      ...credentials,
    };
    adminService
      .registerStudent(params)
      .then(function (response) {
        if (response?.data?.payload?.status === "success") {
          setOpenRegStudentDialog(false);
          setForceReload(true);
          getCredits();
          setTimeout(() => {
            setForceReload(false);
          }, 500);
        }
      })
      .catch(function (error) {
        setError(error?.response?.data?.message);
        setTimeout(() => {
          setError(null);
        }, 4000);
      });
  }

  //--- ---

  useEffect(() => {
    getDomain();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (formState.isSubmitSuccessful || OpenRegTutorDialog === false) {
      reset({
        name: "",
        surname: "",
        email: "",
        password: "",
        phoneMobile: "",
        domain: "",
      });
    }

    if (formState.isSubmitSuccessful || OpenRegStudentDialog === false) {
      reset({
        name: "",
        surname: "",
        email: "",
        password: "",
        phoneMobile: "",
        fiscalCode: "",
        dateofBirth: "",
        birthCity: "",
      });
    }
    // eslint-disable-next-line
  }, [formState.isSubmitSuccessful, reset, OpenRegStudentDialog]);

  const loadUsers = (defaultSearch = "") => {
    let params = {
      token: token,
      page: pagination,
      role: role || "",
      idParent: idParent || 0,
      defaultSearch: defaultSearch,
    };
    adminService
      .getUsers(params)
      .then((response) => {
        setUsers(response?.data?.payload?.data);
        if (response?.data?.payload?.count) {
          setPages(Math.ceil(response?.data?.payload?.count / numberPerPage));
        }
      })
      .catch((error) => {
        setUsers([]);
        setPages(null);
      });
  }; //loadUsers

  const handleCreditGif = (uid) => {
    const howManyCredits = prompt(
      "Attenzione, stai regalando dei crediti da utilizzare.\nQuanti crediti vuoi regalare ?\n\n",
      1
    );

    const token = localStorage.getItem("token");
    const params = {
      token: token,
      howManyCredits: howManyCredits,
      idTutor: uid,
    };
    adminService
      .setCertificateGift(params)
      .then((response) => {
        setForceReload(!forceReload);
      })
      .catch((error) => console.log(error));
  }; //handleCreditGif

  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line
  }, [
    setUsers,
    setPagination,
    pagination,
    dialogDelete,
    role,
    idParent,
    forceReload,
  ]);

  const toggleDialogDelete = (item) => {
    setDialogDelete(!dialogDelete);
    setUserToDelete(item);
  };

  const toggleRegTutorDialog = () => {
    setOpenRegTutorDialog(false);
  }; //toggleRegTutorDialog

  const toggleRegStudentDialog = () => {
    setOpenRegStudentDialog(false);
  }; //toggleRegStudentDialog

  const toggleInfoAccountDialog = () => {
    setOpenInfoAccountDialog(false);
    setUserIdDetail(0);
  }; //toggleInfoAccountDialog

  const handleDeleteUser = () => {
    let params = {
      token: token,
      userId: userToDelete,
    };
    adminService
      .deleteUser(params)
      .then((response) => {
        setDialogDelete(false);
      })
      .catch((error) => {});
  };

  const nextPage = () => {
    setPagination(pagination + 1);
  };

  const prevPage = () => {
    setPagination(pagination - 1);
  };

  let arr = [];

  const getPagination = () => {
    if (pages) {
      for (let i = 1; i <= pages; i++) {
        arr.push(i);
      }
      return arr;
    }
  };

  const goToPage = (item) => {
    setPagination(item - 1);
  };

  const goToSearch = () => {
    //if(defaultSearch.current.value === ''){ return true; }
    loadUsers(defaultSearch.current.value);
    defaultSearch.current.value = "";
  }; //goToSearch

  //---

  return (
    <>
      <Layout>
        <div className="mx-auto">
          <div>
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
              Search
            </label>
            <div className="relative mb-4">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                ref={defaultSearch}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    goToSearch();
                  }
                }}
                className="block w-full p-4 pl-10 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="cognome / email / codice fiscale"
              />
              <button
                type="submit"
                onClick={(e) => {
                  goToSearch();
                }}
                className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 uppercase">
                cerca
              </button>
            </div>
          </div>

          {role === "tutor" && typeof idParent === "undefined" && (
            <div>
              <Button
                className="cursor-pointer"
                onClick={(e) => {
                  handleRegTutorDialog(e);
                }}
                variant="gradient">
                <span className="inline-block align-middle mr-2">
                  <FaUserPlus size={24} />
                </span>
                <span className="inline-block align-middle font-semibold">
                  Nuovo Tutor
                </span>
              </Button>
              <hr className="my-4 h-1 mx-auto bg-blue-100" />
            </div>
          )}

          {localStorage.getItem("role") === "tutor" &&
            dashboardStore.dashboard.credits > 0 && (
              <div>
                <Button
                  className="cursor-pointer"
                  onClick={(e) => {
                    handleRegStudentDialog(e);
                  }}
                  variant="gradient">
                  <span className="inline-block align-middle mr-2">
                    <FaUserPlus size={24} />
                  </span>
                  <span className="inline-block align-middle font-semibold">
                    Nuovo Studente
                  </span>
                </Button>
                <hr className="my-4 h-1 mx-auto bg-blue-100" />
              </div>
            )}

          {/**************************/}

          <table className="w-full border-collapse border border-slate-500 text-left">
            <thead>
              <tr>
                <th className="border border-slate-600 p-2">Nome</th>
                <th className="border border-slate-600 p-2 ">Cognome</th>
                <th className="border border-slate-600 p-2 ">Email</th>
                <th className="border border-slate-600 p-2 ">Telefono</th>
                {/* <th className="border border-slate-600 p-2 ">Codice sconto</th> */}
                {["student", "student-tutor", "tutor"].includes(role) &&
                  localStorage.getItem("role") === "admin" && (
                    <>
                      <th className="border border-slate-600 p-2 ">Dominio</th>
                    </>
                  )}
                {role === "student" &&
                  localStorage.getItem("role") === "tutor" && (
                    <>
                      <th className="border border-slate-600 p-2 ">
                        Data Registrazione
                      </th>
                    </>
                  )}
                {role === "tutor" && typeof idParent === "undefined" && (
                  <>
                    <th className="border border-slate-600 p-2 ">
                      Crediti residui
                    </th>
                    <th className="border border-slate-600 p-2 ">Studenti</th>
                    <th className="border border-slate-600 p-2 ">
                      Attest. Emessi
                    </th>
                  </>
                )}
                {localStorage.getItem("role") !== "tutor" && (
                  <th className="border border-slate-600 p-2 ">Azioni</th>
                )}
              </tr>
            </thead>
            <tbody>
              {users?.map((item, index) => (
                <tr key={index} className="even:bg-gray-70 odd:bg-white">
                  <td className="border border-slate-700 p-2 py-4 ">
                    {item?.name}
                  </td>
                  <td className="border border-slate-700 p-2 py-4 ">
                    {item?.surname}
                  </td>
                  <td className="border border-slate-700 p-2 py-4 ">
                    {item?.email}
                  </td>
                  <td className="border border-slate-700 p-2 py-4 ">
                    {item?.phoneMobile}
                  </td>
                  {["student", "student-tutor", "tutor"].includes(role) &&
                    localStorage.getItem("role") === "admin" && (
                      <>
                        <td className="border border-slate-700 p-2 py-4 ">
                          {item?.domain}
                        </td>
                      </>
                    )}

                  {role === "student" &&
                    localStorage.getItem("role") === "tutor" && (
                      <>
                        <td className="border border-slate-700 p-2 py-4 ">
                          {formatDate(item?.createdAt) || ""}
                        </td>
                      </>
                    )}

                  {role === "tutor" && typeof idParent === "undefined" && (
                    <>
                      <td className="border border-slate-700 p-2 py-4 text-center">
                        {item?.credits || 0}
                      </td>
                      <td className="border border-slate-700 p-2 py-4 text-center">
                        {item?.countChild || 0}
                      </td>
                      <td className="border border-slate-700 p-2 py-4 text-center">
                        {item?.genCertificate || 0}
                      </td>
                    </>
                  )}

                  {localStorage.getItem("role") !== "tutor" && (
                    <td className="border border-slate-700 p-2 py-4 text-center whitespace-nowrap">
                      {["tutor"].includes(item?.role) && (
                        <div className="cursor-pointer inline-block mx-1">
                          <Link to={"/admin/users/tutor/" + item?.id}>
                            <FaUsers size={24} />
                          </Link>
                        </div>
                      )}

                      {["student", "tutor"].includes(item?.role) && (
                        <div
                          className="cursor-pointer inline-block mx-1"
                          onClick={(e) => {
                            handleInfoAccountDialog(item?.id || 0);
                          }}>
                          <FaRegFileAlt size={24} />
                        </div>
                      )}

                      {["student", "tutor"].includes(item?.role) && (
                        <div
                          className="cursor-pointer inline-block mx-1"
                          onClick={() => toggleDialogDelete(item?.id)}>
                          <FaTrashAlt size={24} />
                        </div>
                      )}

                      {["tutor"].includes(item?.role) && (
                        <div
                          className="cursor-pointer inline-block mx-1"
                          onClick={(e) => {
                            handleCreditGif(item?.id || 0);
                          }}>
                          <FaGift size={24} />
                        </div>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>

          <div className={`${styles.pagination} flex flex-wrap justify-center`}>
            {pages !== null && (
              <div className="cursor-pointer" onClick={() => prevPage()}>
                Indietro
              </div>
            )}

            {getPagination()?.map((item, index) => (
              <div
                className={`${styles.pageNumber} ${
                  pagination === index ? styles.pageActive : ""
                }`}
                onClick={() => goToPage(item)}
                key={index}>
                {item}
              </div>
            ))}

            {pages !== null && (
              <div className="cursor-pointer" onClick={() => nextPage()}>
                Avanti
              </div>
            )}
          </div>
        </div>
      </Layout>

      {/** dialog delete */}
      <Dialog
        open={dialogDelete}
        handler={toggleDialogDelete}
        className="w-24 min-w-[74%] max-w-[74%]">
        <DialogHeader>
          Sei sicuro di voler cancellare l'account dell'utente?
        </DialogHeader>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={toggleDialogDelete}
            className="mr-1">
            <span>No, annulla</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={() => handleDeleteUser()}>
            <span>Si, cencella utente</span>
          </Button>
        </DialogFooter>
      </Dialog>

      {/** DIALOG INSERT TUTOR */}
      {localStorage.getItem("role") === "admin" && (
        <Dialog
          open={OpenRegTutorDialog}
          onSubmit={handleSubmit(onSubmitTutor)}
          className="w-24 min-w-[74%] max-w-[74%]">
          <DialogHeader>Registra un Tutor</DialogHeader>
          <form id="formTutor">
            <DialogBody divider>
              <div className="w-full my-2">
                <div className="md:grid grid-cols-2 gap-4">
                  <div className="my-2">
                    <Input
                      label="Nome"
                      type="text"
                      {...register("name", { required: true })}
                    />
                    {errors?.name && (
                      <span className="text-red-900">Inserisci il nome</span>
                    )}
                  </div>
                  <div className="my-2">
                    <Input
                      label="Cognome"
                      type="text"
                      {...register("surname", { required: true })}
                    />
                    {errors?.surname && (
                      <span className="text-red-900">
                        {" "}
                        Inserisci il cognome{" "}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="w-full my-2">
                <div className="md:grid grid-cols-2 gap-4">
                  <div className="my-2">
                    <Input
                      label="Email"
                      type="email"
                      {...register("email", {
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        },
                      })}
                    />
                    {errors?.email && (
                      <span className="text-red-900">Inserisci l'email</span>
                    )}
                  </div>
                  <div className="my-2">
                    <Input
                      label="password"
                      type="text"
                      {...register("password", { required: true })}
                    />
                    {errors?.password && (
                      <span className="text-red-900">
                        {" "}
                        Inserisci la password{" "}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="w-full my-2">
                <div className="md:grid grid-cols-2 gap-4">
                  <div className="my-2">
                    <Input
                      label="cellulare"
                      type="phone"
                      {...register("phoneMobile", {
                        required: true,
                      })}
                    />
                    {errors?.phoneMobile && (
                      <span className="text-red-900">
                        Inserisci il numero di cellulare
                      </span>
                    )}
                  </div>
                  <div className="my-2">
                    <select
                      className="w-full"
                      {...register("domain", { required: true })}>
                      <option value="">seleziona</option>
                      {domains?.data?.map((item, index) => (
                        <option value={`${item.domain}`} key={index}>
                          {item.domain}
                        </option>
                      ))}
                    </select>

                    {errors.domain && (
                      <span className="text-red-900">
                        Devi selezionare un Dominio
                      </span>
                    )}
                  </div>
                </div>
              </div>

              {error === "indirizzo Email già presente." && (
                <div
                  className="w-100 bg-orange-100 border-l-4 border-orange-700 p-4"
                  role="alert">
                  <p className="font-bold">Attenzione</p>
                  <p>stai provando a registrare una email già presente.</p>
                </div>
              )}
            </DialogBody>
            <DialogFooter>
              <Button
                variant="text"
                color="red"
                className="mr-1"
                onClick={() => toggleRegTutorDialog()}>
                <span>Annulla</span>
              </Button>
              <Button variant="gradient" color="green" type="submit">
                <span>Salva</span>
              </Button>
            </DialogFooter>
          </form>
        </Dialog>
      )}

      {/** DIALOG INSERT STUDENT */}
      {localStorage.getItem("role") === "tutor" &&
        dashboardStore.dashboard.credits > 0 && (
          <Dialog
            open={OpenRegStudentDialog}
            onSubmit={handleSubmit(onSubmitStudent)}
            className="w-24 min-w-[74%] max-w-[74%]">
            <DialogHeader>Registra uno Studente</DialogHeader>
            <form id="formStudent">
              <DialogBody divider>
                <div className="w-full my-2">
                  <div className="md:grid grid-cols-2 gap-4">
                    <div>
                      <p className="mb-1">Nome</p>
                      <Input
                        type="text"
                        {...register("name", { required: true })}
                      />
                      {errors?.name && (
                        <span className="text-red-900">Inserisci il nome</span>
                      )}
                    </div>
                    <div>
                      <p className="mb-1">Cognome</p>
                      <Input
                        type="text"
                        {...register("surname", { required: true })}
                      />
                      {errors?.surname && (
                        <span className="text-red-900">
                          {" "}
                          Inserisci il cognome{" "}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="w-full my-2">
                  <div className="md:grid grid-cols-2 gap-4">
                    <div>
                      <p className="mb-1">Email</p>
                      <Input
                        type="email"
                        {...register("email", {
                          required: true,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          },
                        })}
                      />
                      {errors?.email && (
                        <span className="text-red-900">Inserisci l'email</span>
                      )}
                    </div>
                    <div>
                      <p className="mb-1">Password</p>
                      <Input
                        type="text"
                        {...register("password", { required: true })}
                      />
                      {errors?.password && (
                        <span className="text-red-900">
                          {" "}
                          Inserisci la password{" "}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="w-full my-2">
                  <div className="md:grid grid-cols-2 gap-4">
                    <div>
                      <p className="mb-1">Cellulare</p>
                      <Input
                        type="phone"
                        {...register("phoneMobile", {
                          required: true,
                        })}
                      />
                      {errors?.phoneMobile && (
                        <span className="text-red-900">
                          Inserisci il numero di cellulare
                        </span>
                      )}
                    </div>
                    <div></div>
                  </div>
                </div>

                {error === "indirizzo Email già presente." && (
                  <div
                    className="w-100 bg-orange-100 border-l-4 border-orange-700 p-4"
                    role="alert">
                    <p className="font-bold">Attenzione</p>
                    <p>stai provando a registrare una email già presente.</p>
                  </div>
                )}
              </DialogBody>
              <DialogFooter>
                <Button
                  variant="text"
                  color="red"
                  className="mr-1"
                  onClick={() => toggleRegStudentDialog()}>
                  <span>Annulla</span>
                </Button>
                <Button variant="gradient" color="green" type="submit">
                  <span>Salva</span>
                </Button>
              </DialogFooter>
            </form>
          </Dialog>
        )}

      {/** DIALOG INFO STUDENT */}
      <Dialog
        open={OpenInfoAccountDialog}
        className="w-24 min-w-[74%] max-w-[74%]">
        <DialogHeader>Scheda Account</DialogHeader>
        <DialogBody divider>
          <AccountDetailView userId={userIdDetail} />
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            className="mr-1"
            onClick={() => toggleInfoAccountDialog()}>
            <span>Chiudi</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
