import { useState, useEffect } from "react";

import Layout from "../../../components/layout";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Typography,
} from "@material-tailwind/react";
import UpoloadFileTutor from "../../../components/uploadFileTutor";
import { adminService } from "../../../api/admin";
import useDashboardStore from "../../../zustandStore/dashboardStore";
import { FaCheckCircle } from "react-icons/fa";
import PaymentButton from "./paymentButton";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

export default function PriceListViiew() {
  const [dialogUpload, setDialogUpload] = useState(false);
  const [itemIdx, setItemIdx] = useState(false);
  const [cart, setCart] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const dashboardStore = useDashboardStore((state) => {
    return state;
  });

  const pacchetti = [
    {
      title: "BASIC",
      description: "{{attestati}} attestati prepagati",
      priceCad: 13.0,
      priceMain: 0,
      attestati: 10,
      advMsg: "",
      list: [
        "Assistenza h24",
        "Tutor dedicato",
        "Stato di avanzamento dei corsi",
        "piattaforma gratuita",
        "scheda utenti",
      ],
    },
    {
      title: "PREMIUM",
      description: "{{attestati}} attestati prepagati",
      priceCad: 10.0,
      priceMain: 325,
      attestati: 25,
      advMsg: "IL PIU VENDUTO",
      list: [
        "Assistenza h24",
        "Tutor dedicato",
        "Stato di avanzamento dei corsi",
        "piattaforma gratuita",
        "scheda utenti",
      ],
    },
    {
      title: "INFINITY",
      description: "{{attestati}} attestati prepagati",
      priceCad: 8.0,
      priceMain: 650,
      attestati: 50,
      advMsg: "",
      list: [
        "Assistenza h24",
        "Tutor dedicato",
        "Stato di avanzamento dei corsi",
        "piattaforma gratuita",
        "scheda utenti",
      ],
    } /*,
    {
      title: "Pacchetto 4",
      description: "{{attestati}} attestati prepagati",
      priceCad: 8.00,
      attestati: 75
    }*/,
  ];

  const toggleDialogUpload = () => {
    setDialogUpload(!dialogUpload);
  };

  const getCredits = () => {
    adminService
      .getCredits({ token: localStorage.getItem("token") || "" })
      .then((response) => {
        if (response?.data?.payload) {
          dashboardStore.dashboard.credits =
            response?.data?.payload.data[0].credit || 0;
          useDashboardStore.setState({ ...dashboardStore });
        }
      })
      .catch((error) => {});
  };

  const getCart = () => {
    adminService
      .getCart({ token: localStorage.getItem("token") || "" })
      .then((response) => {
        if (response?.data?.payload) {
          setCart(response?.data?.payload.data);
        }
      })
      .catch((error) => {
        setCart(false);
      });
  };

  async function cartInsert(data) {
    const params = {
      ...data,
    };
    adminService
      .cartInsert(params)
      .then(function (response) {
        if (response?.data?.payload?.status === "success") {
          getCart();
        }
      })
      .catch(function (error) {
        alert("si è verificato un errore");
      });
  }

  const buyNow = (e, idx) => {
    setItemIdx(idx);

    const data = {
      token: localStorage.getItem("token") || "",
      item: pacchetti[idx]?.title,
      credits: pacchetti[idx]?.attestati || 0,
    };
    cartInsert(data);
  };

  useEffect(() => {
    //getCart();
  }, []);

  useEffect(() => {
    if (isFileUploaded === true) {
      setIsFileUploaded(false);
      setItemIdx(false);
      setCart(false);
      getCredits();
    }
    // eslint-disable-next-line
  }, [isFileUploaded]);

  //--- ---

  return (
    <>
      <Layout>
        <div className="mx-auto">
          <section className="bg-white">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
              <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                <h2 className="mb-4 text-4xl tracking-tight font-bold text-gray-800 dark:text-white">
                  Profili disponibili
                </h2>
                <p className="mb-5 font-light text-gray-800 sm:text-xl">
                  seleziona il profilo necessario per le tue esigenze. Puoi
                  acquistare un nuovo profilo in qualsiasi momento aumentando la
                  disponibilità in qualsiasi momento.
                </p>
              </div>
              <div className="space-y-4 lg:grid lg:grid-cols-3 sm:gap-4 lg:space-y-0">
                {pacchetti.map((item, idx) => {
                  return (
                    <div
                      key={`item-${idx}`}
                      className={`flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 rounded-lg border border-gray-100 shadow xl:p-8 w-full ${
                        itemIdx !== idx ? "bg-white" : "bg-green-100"
                      }`}>
                      {item.advMsg !== "" ? (
                        <h3 className="p-2 mb-2 text-2xl font-bold bg-orange-500 text-white">
                          {item.advMsg}
                        </h3>
                      ) : (
                        <h3 className="mb-14">{""}</h3>
                      )}
                      <h3 className="p-2 mb-2 text-2xl font-bold bg-cyan-500">
                        {item.title}
                      </h3>

                      {item?.priceMain ? (
                        <div className="flex justify-center items-baseline my-0">
                          <span className="mr-1 text-3xl font-bold line-through decoration-red-500 decoration-2">
                            {parseFloat(item?.priceMain) > 0
                              ? parseFloat(item?.priceMain).toFixed(2) + " €"
                              : ""}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}

                      <div
                        className={`flex justify-center items-baseline my-0 ${
                          item?.priceMain === 0 ? "mt-10" : ""
                        }`}>
                        <span className="mr-1 text-3xl font-bold">
                          {parseFloat(item.priceCad * item.attestati).toFixed(
                            2
                          )}{" "}
                          €
                        </span>
                      </div>

                      <p className="sm:text-lg font-semibold mt-2">
                        {item.description.replaceAll(
                          "{{attestati}}",
                          item.attestati
                        )}
                      </p>

                      <div className="flex justify-center items-baseline my-1">
                        <span className="mr-1 text-1xl font-semibold">
                          {parseFloat(item.priceCad).toFixed(2)}
                        </span>
                        <span className="text-1xl text-gray-600 ">
                          euro/cad.
                        </span>
                      </div>

                      <PayPalScriptProvider
                        deferLoading={false}
                        options={{
                          "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                          currency: "EUR",
                        }}>
                        <PaymentButton
                          buyNow={buyNow}
                          item={item}
                          idx={idx}
                          itemIdx={itemIdx}
                        />
                      </PayPalScriptProvider>

                      <ul className="mb-6">
                        {item.list.map((row, idx) => {
                          return (
                            <li
                              key={`pp_${idx}`}
                              className="py-1 text-md font-medium full-w text-left flex items-center">
                              <FaCheckCircle
                                size={20}
                                className="mr-2 text-orange-500"
                              />{" "}
                              {row}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>

          <section className={`mt-3 bg-white ${cart === false && "hidden"}`}>
            <div className="py-4 px-4 mx-auto max-w-screen-xl">
              <div className="mx-auto text-center">
                <h2 className="mb-4 text-xl font-semibold text-gray-800 mx-auto max-w-xl">
                  quando eseguirai il bonifico per il <b>{cart[0]?.item}</b>{" "}
                  invia la ricevuta di bonifico. Il servizio sarà attivato
                  automaticamente.
                </h2>
                <Button
                  className="cursor-pointer"
                  onClick={(e) => {
                    toggleDialogUpload(e, cart[0]?.item || "");
                  }}
                  variant="gradient">
                  <span className="text-1xl font-semibold">
                    invia ricevuta bonifico
                  </span>
                </Button>
              </div>

              <div className="py-4 px-4 mx-auto max-w-screen-xl">
                <div className="mx-auto text-center mb-8 lg:mb-12">
                  <h2 className="mb-4 text-3xl font-bold text-gray-800 dark:text-white">
                    procedura di acquisto{" "}
                    {pacchetti[itemIdx]?.title || cart[0]?.item || ""}
                  </h2>
                  <p className="mb-5 font-light text-gray-800 sm:text-xl">
                    effettua un bonifico bancario dell'importo relativo al
                    pacchetto che vuoi acquistare. Dopo aver effettuato il
                    bonifico bancario inviaci la ricevuta del bonifico
                    allegandola al modulo in basso in questa pagina.
                  </p>

                  <hr />

                  <Typography className="text-left my-4 text-1xl">
                    <div className="font-semibold">
                      Banca Intesa San Paolo SpA
                    </div>
                    <div>intestato a: Calogero Cecato</div>
                    <div>IBAN: IT42 A030698 31701 0000000 8840</div>
                    <div>
                      Causale:{" "}
                      {pacchetti[itemIdx]?.title || cart[0]?.item || ""}
                    </div>
                  </Typography>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>

      {/** dialog upload ricevuta bonifico */}
      <Dialog
        open={dialogUpload}
        handler={toggleDialogUpload}
        className="w-24 min-w-[74%] max-w-[74%]">
        <DialogHeader>Carica la ricevuta del bonifico</DialogHeader>

        <DialogBody>
          <UpoloadFileTutor
            getCart={getCart}
            cart={cart}
            setIsFileUploaded={setIsFileUploaded}
          />
        </DialogBody>

        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={toggleDialogUpload}
            className="mr-1">
            <span>chiudi</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
