import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";

import {
  Input,
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
  DialogFooter,
} from "@material-tailwind/react";
import { adminService } from "../../api/admin";

function DialogEditAdminCertificate(props) {
  const { open, handler, certificate, userid } = props;
  const [user, setUser] = useState(null);
  const [, /*currentUser*/ setCurrentUser] = useState();

  let token = localStorage.getItem("token");

  useEffect(() => {
    setCurrentUser(userid);
  }, [userid]);

  useEffect(() => {
    if (userid) {
      let params = {
        token,
        registrationcode: certificate.registrationCode,
      };

      adminService
        .detailCertificate(params)
        .then((response) => {
          setUser(response?.data?.payload?.data);
        })
        .catch((error) => console.log(error));
    }
    // eslint-disable-next-line
  }, [setUser, userid]);

  const {
    register,
    setValue,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm({
    // defaultValues: {
    // },
  });

  //-- on submit form
  const onSubmit = (data) => {
    const dateOfBirth =
      data?.yearBirth + "-" + data?.monthBirth + "-" + data?.dayBirth;

    //-- token
    const token = localStorage.getItem("token");
    const params = {
      token: token,
      idCertificate: props.certificate.id,
      name: data?.name,
      surname: data?.surname,
      fiscalCode: data?.fiscalCode.toUpperCase(),
      dateOfBirth: dateOfBirth,
      birthCity: data?.birthCity,
      birthProvince: data?.birthProvince,
    };
    adminService
      .updatCertificate(params)
      .then((response) => {
        handler(false);
      })
      .catch((error) => console.log(error));
  };

  const getYears = () => {
    const thisYear = new Date().getFullYear();
    const yearStartFrom = 1910;
    let yearsArr = [];
    for (let i = thisYear; i >= yearStartFrom; i--) {
      yearsArr.push(i);
    }
    return yearsArr;
  };

  const days = [...Array(31).keys()].map((x) => ++x);
  const months = [...Array(12).keys()].map((x) => ++x);
  const years = getYears();

  const getDayOfBirth = (date) => {
    return new Date(date).getDate();
  };

  const getMonthOfBirth = (date) => {
    return new Date(date).getMonth() + 1;
  };

  const getYearfBirth = (date) => {
    return new Date(date).getFullYear();
  };

  useEffect(() => {
    setValue("name", user?.name);
    setValue("surname", user?.surname);
    setValue("fiscalCode", user?.fiscalCode);
    setValue("birthCity", user?.birthCity);
    setValue("birthProvince", user?.birthProvince);
    setValue("dayBirth", getDayOfBirth(user?.dateOfBirth));
    setValue("monthBirth", getMonthOfBirth(user?.dateOfBirth));
    setValue("yearBirth", getYearfBirth(user?.dateOfBirth));
    // eslint-disable-next-line
  }, [setUser, user]);

  return (
    <Dialog open={open} handler={handler} size="xl">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader>Modifica dati attestato.</DialogHeader>
        <DialogBody divider>
          <div className="w-full">
            <div className="lg:grid grid-cols-2 gap-4">
              <div className="mt-4 lg:mt-0">
                <Input
                  label="Nome"
                  type="text"
                  {...register("name", { required: true })}
                />
                {errors?.name && (
                  <span className="text-red-900">Inserisci il nome</span>
                )}
              </div>
              <div className="mt-4 lg:mt-0">
                <Input
                  label="Cognome"
                  type="text"
                  {...register("surname", { required: true })}
                />
                {errors?.surname && (
                  <span className="text-red-900">Inserisci il cognome</span>
                )}
              </div>
            </div>

            <div className="lg:grid grid-cols-2 gap-4 mt-0 lg:mt-4">
              <div className="mt-4 lg:mt-0">
                <Input
                  label="Codice Fiscale"
                  type="text"
                  {...register("fiscalCode", { required: true })}
                />
                {errors?.fiscalCode && (
                  <span className="text-red-900">
                    Inserisci il codice fiscale
                  </span>
                )}
              </div>
              <div className="mt-4 lg:mt-0">
                <div className="flex flex-wrap gap-2">
                  <div>
                    <select {...register("dayBirth", { required: true })}>
                      {days?.map((item, index) => (
                        <option value={`${item}`} key={index}>
                          {item}
                        </option>
                      ))}
                    </select>

                    {errors.dayBirth && (
                      <span className="text-red-900">
                        Inserisci il tuo giorno di nascita
                      </span>
                    )}
                  </div>
                  <div>
                    <select
                      label="Mese"
                      {...register("monthBirth", { required: true })}>
                      {months?.map((item, index) => (
                        <option value={`${item}`} key={index}>
                          {item}
                        </option>
                      ))}
                    </select>
                    {errors.monthBirth && (
                      <span className="text-red-900">
                        Inserisci il tuo mese di nascita
                      </span>
                    )}
                  </div>
                  <div>
                    <select
                      label="Anno"
                      {...register("yearBirth", { required: true })}>
                      {years?.map((item, index) => (
                        <option value={`${item}`} key={index}>
                          {item}
                        </option>
                      ))}
                    </select>
                    {errors.yearBirth && (
                      <span className="text-red-900">
                        Inserisci il tuo anno di nascita
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:grid grid-cols-2 gap-4 mt-4">
              <div className="mt-4 lg:mt-0">
                <Input
                  label="Comune"
                  type="text"
                  {...register("birthCity", { required: true })}
                />
                {errors.birthCity && (
                  <span className="text-red-900">
                    Inserisci la tua città di nascita
                  </span>
                )}
              </div>
              <div className="mt-4 lg:mt-0">
                <Input
                  label="Provincia"
                  type="text"
                  {...register("birthProvince", { required: true })}
                />
                {errors.birthProvince && (
                  <span className="text-red-900">
                    Inserisci la tua provincia di nascita
                  </span>
                )}
              </div>
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => handler(null)}
            className="mr-1">
            <span>Annulla</span>
          </Button>
          <Button variant="gradient" color="green" type="submit">
            <span>Salva</span>
          </Button>
        </DialogFooter>
      </form>
    </Dialog>
  );
}

export default DialogEditAdminCertificate;
