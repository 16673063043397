import React from "react";
import { FaDownload } from "react-icons/fa";
import { adminService } from "../../../api/admin";
import PdfCreate from "./PdfCreate";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import moment from "moment";

const ExportPDF = (prop) => {
  const createPdf = async () => {
    let token = localStorage.getItem("token");

    let params = {
      token: token,
      date: prop.date,
      courseType: prop.courseType,
    };

    try {
      const response = await adminService.downloadCertificatesDailyReport(
        params
      );

      const filename =
        "reportGiornalieroCertificati_" +
        prop.date.replace(/-/g, "") +
        "_" +
        prop.courseType +
        ".pdf";

      // Usa il metodo async `toBlob` per ottenere il blob
      const blob = await pdf(
        <PdfCreate
          data={response.data.payload.data}
          courseType={prop.courseType}
          currentDate={moment(prop.date).format("DD/MM/YYYY")}
          protocolloAA={moment(prop.protocolloAA).format("DD/MM/YYYY")}
        />
      ).toBlob();
      saveAs(blob, filename);
    } catch (error) {
      console.error("Impossibile scaricare il report:", error);
      //alert("error");
    }
  };

  return (
    <div>
      <FaDownload
        className={prop.class}
        onClick={() => {
          createPdf();
        }}
      />
      <div style={{textAlign: "center"}}>{prop.cnt}</div>
    </div>
  );
};

export default ExportPDF;
