import { proxyBE } from "./common";

export const certificateService = {
  verify(data) {
    const params = data?.params;
    return proxyBE.get("userCertification/verifyCheckData", { params });
  },
  verifyQrCode(data) {
    const params = {
      verify: data?.verify,
    };
    return proxyBE.get("userCertification/verifyCheck", { params });
  },
};
