import { proxyBE } from "./common";

export const courseService = {
  courses(data) {
    const params = { token: data.token };

    return proxyBE.get("trainingOffer/courses", { params });
  },

  course(data) {
    const params = { ...data.params };
    return proxyBE.get("trainingOffer/course", { params });
  },

  getPriceByDomain(data) {
    const params = { ...data.params };
    return proxyBE.get("userCourse/priceByDomain", { params });
  },

  activeCourse(data) {
    return proxyBE.post("userCourse/enroll", data);
  },

  setTracking(data) {
    const params = { ...data?.params };
    return proxyBE.post("userCourse/setTracking", params);
  },

  getTracking(data) {
    const params = { ...data?.params };
    return proxyBE.get("userCourse/getTracking", { params });
  },

  userCourse(data) {
    const params = { ...data.params };
    return proxyBE.post("userCourse/enroll", params);
  },

  getCourse(data) {
    const params = { ...data.params };
    return proxyBE.get("userCourse/getCourse", { params });
  },

  getAllCourseCourse(data) {
    const params = { ...data.params };
    return proxyBE.get("userCourse/getAllCourse", { params });
  },

  setTrakingLessonDone(data) {
    const params = { ...data?.params };
    return proxyBE.post("userCourse/setTrackingLessonDone", params);
  },

  checkPayment(params) {
    return proxyBE.post("userCourse/checkPayment", params);
  },
};
