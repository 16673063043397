import Layout from "../../components/layout";

export default function ThankYoutPaymentTutor() {

  return (
    <Layout>
      <div className="container mx-auto">
        <div className="mt-4">
          <p className="text-2xl font-bold mb-4">
            Il pagamento è andato a buon fine.
          </p>
          <p>
            Grazie! Il pagamento è stato effettuato. <br />
            Stiamo attivando il Servizio acquistato.
          </p>
        </div>
      </div>
    </Layout>
  );
}
