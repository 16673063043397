import { IconPlayCircle, IconCheckCircle } from "../../icons";

function CardLesson({ data }) {
  const { title, isDone } = data || {};
  return (
    <div className="flex flex-row aligns-center mb-10 cursor-pointer">
      <div>
        {isDone ? (
          <div className="text-lime-500">
            <IconCheckCircle />
          </div>
        ) : (
          <div className="text-sky-500">
            <IconPlayCircle />
          </div>
        )}
      </div>
      <div className="ml-2">
        <div className="font-bold">{title}</div>
      </div>
    </div>
  );
}

export default CardLesson;
