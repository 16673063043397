import React, { useEffect, useRef, useState } from "react";

import { FaDownload } from "react-icons/fa";
import { adminService } from "../../../api/admin";
import dayjs from "dayjs";
import ExportPDF from "./ExportPdf";

export default function Calendar() {
  const [prevMonthDays, setprevMonthDays] = useState(null);
  const [nextMonthDays, setnextMonthDays] = useState(null);
  const [currentMonthDays, setcurrentMonthDays] = useState(null);
  const [currentDay, setcurrentDay] = useState(null);
  const [, /*currentDate*/ setcurrentDate] = useState(null);
  const [currentYear, setcurrentYear] = useState(null);
  const [currentSelect, setcurrentSelect] = useState([]);
  const [firstDayOfMonth, setFirstDayOfMonth] = useState([]);
  const [payloadCount, setPayLoadCount] = useState([]);
  const [appConfig, setAppConfig] = useState();

  const month = useRef();
  const year = useRef();

  let token = localStorage.getItem("token");

  const setCurrentCalendar = () => {
    setcurrentSelect([month.current.value, year.current.value]);
  }; //setCurrentCalendar

  const loadCalendarDataCount = (date) => {
    let params = {
      token: token,
      month: date[0],
      year: date[1],
    };

    adminService
      .getCalendarDataCount(params)
      .then((response) => {
        setPayLoadCount(response.data.payload.data);
      })
      .catch((error) => {
        setPayLoadCount([]);
        //alert("error");
      });
  }; //loadCalendarDataCount

  const getAppConfig = () => {
    adminService
      .getAppConfig({ token })
      .then((response) => {
        setAppConfig(response?.data?.payload);
      })
      .catch((error) => {});
  }; //getAppConfig

  useEffect(() => {
    const currentDate = dayjs();
    setcurrentDate(currentDate);
    setcurrentDay(currentDate.format("DD")); //day--current

    const prevMonthDaysLastTwoDays = currentDate
      .subtract(1, "month")
      .endOf("month");
    setprevMonthDays(prevMonthDaysLastTwoDays.format("DD"));

    const nextMonthDaysLastTwoDays = currentDate.add(1, "month").endOf("month");
    setnextMonthDays(nextMonthDaysLastTwoDays.format("DD"));

    const currentMonthDays = currentDate.daysInMonth();
    setcurrentMonthDays(currentMonthDays);

    const firstDayOfMonth = currentDate.startOf("month").format("dddd");
    const dayOfWeek = currentDate.startOf("month").day();
    setFirstDayOfMonth([firstDayOfMonth, dayOfWeek]);

    const currentYear = currentDate.format("YYYY");
    setcurrentYear(currentYear);

    setcurrentSelect([currentDate.format("MM"), currentYear]);
    loadCalendarDataCount([
      parseInt(currentDate.format("MM")),
      parseInt(currentYear),
    ]);

    getAppConfig();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const currentDate = dayjs(
      `${currentSelect[1]}-${currentSelect[0]}-${dayjs().format("DD")}`
    );
    setcurrentDate(currentDate);
    setcurrentDay(currentDate.format("DD")); //day--current

    const prevMonthDaysLastTwoDays = currentDate
      .subtract(1, "month")
      .endOf("month");
    setprevMonthDays(prevMonthDaysLastTwoDays.format("DD"));

    const nextMonthDaysLastTwoDays = currentDate.add(1, "month").endOf("month");
    setnextMonthDays(nextMonthDaysLastTwoDays.format("DD"));

    const currentMonthDays = currentDate.daysInMonth();
    setcurrentMonthDays(currentMonthDays);

    const firstDayOfMonth = currentDate.startOf("month").format("dddd");
    const dayOfWeek = currentDate.startOf("month").day();
    setFirstDayOfMonth([firstDayOfMonth, dayOfWeek]);

    loadCalendarDataCount([
      parseInt(currentSelect[0]),
      parseInt(currentSelect[1]),
    ]);

    getAppConfig();

    /*const currentYear = currentDate.format("YYYY");
    setcurrentYear(currentYear);*/

    // eslint-disable-next-line
  }, [currentSelect]);

  //---

  if (prevMonthDays === null || nextMonthDays === null || appConfig === undefined || appConfig === 'undefined') {
    return true;
  }

  return (
    <div className="calendar-container">
      <div className="calendar-header">
        <select
          ref={month}
          onChange={() => {
            setCurrentCalendar();
          }}
          className="cursor-pointer m-2 p-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option value={1} selected={parseInt(currentSelect[0]) === 1}>
            Gennaio
          </option>
          <option value={2} selected={parseInt(currentSelect[0]) === 2}>
            Febbraio
          </option>
          <option value={3} selected={parseInt(currentSelect[0]) === 3}>
            Marzo
          </option>
          <option value={4} selected={parseInt(currentSelect[0]) === 4}>
            Aprile
          </option>
          <option value={5} selected={parseInt(currentSelect[0]) === 5}>
            Maggio
          </option>
          <option value={6} selected={parseInt(currentSelect[0]) === 6}>
            Giugno
          </option>
          <option value={7} selected={parseInt(currentSelect[0]) === 7}>
            Luglio
          </option>
          <option value={8} selected={parseInt(currentSelect[0]) === 8}>
            Agosto
          </option>
          <option value={9} selected={parseInt(currentSelect[0]) === 9}>
            Settembre
          </option>
          <option value={10} selected={parseInt(currentSelect[0]) === 10}>
            Ottobre
          </option>
          <option value={11} selected={parseInt(currentSelect[0]) === 11}>
            Novembre
          </option>
          <option value={12} selected={parseInt(currentSelect[0]) === 12}>
            Dicembre
          </option>
        </select>

        <select
          ref={year}
          onChange={() => {
            setCurrentCalendar();
          }}
          className="cursor-pointer m-2 p-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          {Array.from({ length: currentYear - 2022 }, (_, i) => (
            <option
              key={i}
              value={2023 + i}
              selected={parseInt(currentSelect[1]) === 2023 + i}>
              {2023 + i}
            </option>
          ))}
        </select>

        <ul className="calendar-header-subtitle">
          <li>
            <FaDownload className="svgHaccp6" size={22} /> HACCP 6
          </li>
          <li>
            <FaDownload className="svgHaccp12" size={22} /> HACCP 12
          </li>
        </ul>

        {(payloadCount || []).length === 0 ? (
          <>
            <div
              id="alert-border-2"
              className="flex items-center p-4 mb-0 mt-2 mx-auto max-w-[480px] text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400 dark:bg-gray-800 dark:border-red-800"
              role="alert">
              <svg
                className="flex-shrink-0 w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <div className="ms-3 text-sm font-medium">
                Nessun dato disponibile per questo filtro
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>

      <div className="calendar">
        <span className="day-name">Lunedì</span>
        <span className="day-name">Martedì</span>
        <span className="day-name">Mercoledì</span>
        <span className="day-name">Giovedì</span>
        <span className="day-name">Venerdi</span>
        <span className="day-name">Sabato</span>
        <span className="day-name">Domenica</span>

        {Array.from({ length: (firstDayOfMonth[1] - 1 < 0 ? 6 : (firstDayOfMonth[1] - 1)) }, (_, i) => (
          <div key={i} className="day day--disabled">
            <p>
              {parseInt(prevMonthDays) - (firstDayOfMonth[1] - 1 < 0 ? 6 : (firstDayOfMonth[1] - 1)) + (i + 1)}
            </p>
          </div>
        ))}

        {Array.from({ length: currentMonthDays }, (_, i) => (
          <div
            key={i}
            className={`day ${
              parseInt(currentDay) === i + 1 ? "day day--current" : ""
            }`}>
            <p>{i + 1}</p>
            <ul className="contentDay">
              {/** haccp6 */}
              {(payloadCount || []).filter(
                (item) =>
                  item.countHaccp6 > 0 &&
                  item.releasedAt ===
                    `${currentSelect[1]}-${String(currentSelect[0]).padStart(
                      2,
                      "0"
                    )}-${String(i + 1).padStart(2, "0")}`
              ).length === 1 ? (
                <li className="cursorPointer">
                  <ExportPDF
                    class={"svgHaccp6"}
                    protocolloAA={appConfig?.data[0]?.protocolloAA}
                    cnt={
                      (payloadCount || []).filter(
                        (item) =>
                          item.countHaccp6 > 0 &&
                          item.releasedAt ===
                            `${currentSelect[1]}-${String(
                              currentSelect[0]
                            ).padStart(2, "0")}-${String(i + 1).padStart(
                              2,
                              "0"
                            )}`
                      )[0].countHaccp6
                    }
                    courseType={"haccp6"}
                    date={`${currentSelect[1]}-${String(
                      currentSelect[0]
                    ).padStart(2, "0")}-${String(i + 1).padStart(2, "0")}`}
                  />
                </li>
              ) : (
                ""
              )}

              {/** haccp12 */}
              {(payloadCount || []).filter(
                (item) =>
                  item.countHaccp12 > 0 &&
                  item.releasedAt ===
                    `${currentSelect[1]}-${String(currentSelect[0]).padStart(
                      2,
                      "0"
                    )}-${String(i + 1).padStart(2, "0")}`
              ).length === 1 ? (
                <li className="cursorPointer">
                  <ExportPDF
                    class={"svgHaccp12"}
                    protocolloAA={appConfig?.data[0]?.protocolloAA}
                    cnt={
                      (payloadCount || []).filter(
                        (item) =>
                          item.countHaccp12 > 0 &&
                          item.releasedAt ===
                            `${currentSelect[1]}-${String(
                              currentSelect[0]
                            ).padStart(2, "0")}-${String(i + 1).padStart(
                              2,
                              "0"
                            )}`
                      )[0].countHaccp12
                    }
                    courseType={"haccp12"}
                    date={`${currentSelect[1]}-${String(
                      currentSelect[0]
                    ).padStart(2, "0")}-${String(i + 1).padStart(2, "0")}`}
                  />
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        ))}

        {Array.from(
          { length: 42 - ((firstDayOfMonth[1] - 1 < 0 ? 6 : (firstDayOfMonth[1] - 1)) + currentMonthDays) },
          (_, i) => (
            <div key={i} className="day day--disabled">
              <p>{i + 1}</p>
            </div>
          )
        )}

        {/*<section className="task task--warning">Projects</section>
          <section className="task task--danger">Design Sprint</section>
          <section className="task task--primary">
            Product Checkup 1
            <div className="task__detail">
              <h2>Product Checkup 1</h2>
              <p>15-17th November</p>
            </div>
          </section>
          <section className="task task--info">Product Checkup 2</section>*/}
      </div>
    </div>
  );
}
