import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { userService } from "../../api/user";

import {
  FaCcPaypal
} from "react-icons/fa";
import { courseService } from "../../api/course";
import { useEffect } from "react";
import { adminService } from "../../api/admin";

const PayPalCheckout = (props) => {
  const [paidFor, setPaidFor] = useState(false);
  const [error, setError] = useState(null);
  const { userData, course, registrationCode } = props || "";
  let navigate = useNavigate();

  //---
  const [priceDomain, setPriceDomain] = useState(0);
  const [priceDiscountDomain, setPriceDiscountDomain] = useState(0);
  const [allow, setAllow] = useState(false);
  const [appConfig, setAppConfig] = useState();

  const token = localStorage.getItem("token");

  /** */
  const getAppConfig = () => {
    adminService.getAppConfig({ token }).then((response) => {
      setAppConfig(response?.data?.payload);
    }).catch((error) => {
    })
  }//getAppConfig

  const getPriceByUserDomain = () => {

    const params = {
      token,
      courseCode: course.code
    };

    courseService.getPriceByDomain({ params })
      .then((response) => {
        //console.log(response?.data?.payload)
        setPriceDomain((response?.data?.payload?.price > 0) ? response?.data?.payload?.price : course.price);
        setPriceDiscountDomain((response?.data?.payload?.priceDiscount > 0) ? response?.data?.payload?.priceDiscount : course.priceDiscount);
        setAllow(true)
      })
      .catch((error) => { });
  }//getPriceByUserDomain

  useEffect(() => {
    getAppConfig();
    getPriceByUserDomain();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // eslint-disable-next-line
  }, [priceDiscountDomain, priceDomain, allow])
  //---

  const hanldeApprove = (orderID) => {
    // ok response
    const token = localStorage.getItem("token");
    const params = {
      token: token,
      registrationCode: registrationCode,
      paymentType: "paypal",
      paymentTransactionID: orderID,
      paymentAmount:
        userData?.userBonus && userData?.userBonus !== null
          ? 16
          : ((priceDomain * parseFloat(appConfig?.data[0]?.iva || 0)) / 100) + priceDomain,
      usedBonus: userData?.userBonus,
    };
    userService
      .setPayed({ params })
      .then((response) => {
        setPaidFor(true);
      })
      .catch((error) => {
        // console.log(error);
      });

    //ko response
  };

  if (paidFor) {
    navigate("/thankYouPayment");
  }

  if (error) {
    navigate("/wrongPayment", { state: { course: course?.code } });
  }

  if (allow === false) { return false }

  return (

    <PayPalScriptProvider
      options={{
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: "EUR",
      }}
    >
      <div className="mt-4 bg-white p-4">
        <div className="flex flex-wrap items-center mb-4">
          <FaCcPaypal size={36} />
          <p className="text-xl ml-2 font-bold">Paypal</p>
        </div>
        <hr />
        <div className="mt-4">
          <p>Paga in modo sicuro con PayPal e ottieni subito l'attestato.</p>
          <p>
            Dopo aver effettuato il pagamento puoi stampare e condividere il tuo
            attestato direttamente dalla tua area privata.
          </p>
          <p>Per pagare con carta di credito o PayPal clicca qui:</p>
        </div>
        <div className="mt-4">
          <PayPalButtons
            style={{
              color: "gold",
              height: 48,
              layout: "horizontal",
              tagline: "false",
              shape: "pill",
            }}
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    description: course?.title,
                    amount: {
                      value:
                        userData?.userBonus && userData?.userBonus !== null
                          ? 16
                          : ((priceDomain * parseFloat(appConfig?.data[0]?.iva || 0)) / 100) + priceDomain,
                      currency: "EUR",
                    },
                  },
                ],
              });
            }}
            onApprove={async (data, actions) => {
              const order = await actions.order.capture();
              hanldeApprove(data.orderID);
            }}
            onError={(err) => {
              setError(err);
            }}
            onCancel={() => {
              navigate("/wrongPayment", { state: { course: course } });
            }}
          />
        </div>
      </div>
    </PayPalScriptProvider>
  );
};

export default PayPalCheckout;
