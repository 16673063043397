import { useState, useEffect, useRef } from "react";

import Layout from "../../../components/layout";

import { FaRegEdit, FaDownload } from "react-icons/fa";

import DialogEditAdminCertificate from "../../../components/dialogEditAdminCertificate";
import DialogDeleteAdminCertificate from "../../../components/dialogDeleteAdminCertificate";
import DialogDownloadAdminCertificate from "../../../components/dialogDownloadAdminCertificate";

import { adminService } from "../../../api/admin";

import styles from "./style.module.scss";
import { useLocation } from "react-router-dom";

export default function CertificatesAdminView() {
  const [dialogEditCertificate, setDialogEditCertificate] = useState(false);
  const [dialogDeleteCertificate /*, setDialogDeleteCertificate*/] =
    useState(false);
  const [dialogDownloadCertificate, setDialogDownloadCertificate] =
    useState(false);
  const [certificates, setCertificates] = useState();
  const [userData, setUserData] = useState(null);
  const [qrCodeData, setQrCodeData] = useState(null);
  const [pagination, setPagination] = useState(0);
  const [pages, setPages] = useState(null);
  const [certificate, setCertificate] = useState(null);
  const [, /*isLoadedCertificate*/ setIsLoadedCertificate] = useState(false);
  const [currentUser, setCurrentUser] = useState(false);

  const defaultSearch = useRef();
  const { pathname } = useLocation();

  let token = localStorage.getItem("token");
  let numberPerPage = 200;

  let filterByPath = "";
  if (pathname === "/admin/certificates") {
    filterByPath = "admin";
  }
  if (pathname === "/admin/certificates-tutor") {
    filterByPath = "tutor";
  }

  const loadCertificates = (defaultSearch = "") => {
    let params = {
      token: token,
      page: pagination,
      defaultSearch: defaultSearch,
      filterByPath: filterByPath,
    };

    adminService
      .certificates(params)
      .then((response) => {
        setCertificates(response?.data?.payload?.data);
        if (response?.data?.payload?.count) {
          setPages(Math.ceil(response?.data?.payload?.count / numberPerPage));
        }
      })
      .catch((error) => {});
  }; //loadCertificates

  useEffect(() => {
    loadCertificates();
    // eslint-disable-next-line
  }, [pathname, pagination, dialogEditCertificate]);

  useEffect(() => {
    setCurrentUser(false);
  }, [setCurrentUser]);

  useEffect(() => {
    setIsLoadedCertificate(false);
  }, [setIsLoadedCertificate, userData]);

  const getUserData = (userid) => {
    if (userid) {
      let params = {
        token,
        userid,
      };

      adminService
        .detailUser(params)
        .then((response) => {
          setUserData(response?.data?.payload?.data);
          setIsLoadedCertificate(true);
        })
        .catch((error) => console.log(error));
    }
  };

  const toggleEditCertificate = (item) => {
    getUserData(item?.userId);
    setCertificate(item);
    setCurrentUser(item?.userId);
    setDialogEditCertificate(!dialogEditCertificate);
  };

  const toggleDeleteCertificate = () => {
    //setDialogDeleteCertificate(!dialogDeleteCertificate);
  };

  const toggleDownloadCertificate = (item) => {
    setDialogDownloadCertificate(!dialogDownloadCertificate);
    setQrCodeData(item);
  };

  const nextPage = () => {
    setPagination(pagination + 1);
  };

  const prevPage = () => {
    setPagination(pagination - 1);
  };

  const goToPage = (item) => {
    setPagination(item - 1);
  };

  let arr = [];

  const getPagination = () => {
    if (pages) {
      for (let i = 1; i <= pages; i++) {
        arr.push(i);
      }
      return arr;
    }
  };

  const goToSearch = () => {
    //if(defaultSearch.current.value === ''){ return true; }
    loadCertificates(defaultSearch.current.value);
    defaultSearch.current.value = "";
  }; //goToSearch

  //--- ---

  const formatDate = (date) => {
    if (date === null) {
      return true;
    }
    const dataString = date;
    const data = new Date(dataString);
    const day = String(data.getDate()).padStart(2, "0");
    const month = String(data.getMonth() + 1).padStart(2, "0");
    const year = String(data.getFullYear());

    return `${day}/${month}/${year}`;
  };

  //--- ---

  return (
    <>
      <Layout>
        <div className="mx-auto">
          <div>
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                ref={defaultSearch}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    goToSearch();
                  }
                }}
                className="block w-full p-4 pl-10 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="protocollo / cognome / codice fiscale"
              />
              <button
                type="submit"
                onClick={(e) => {
                  goToSearch();
                }}
                className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 uppercase">
                cerca
              </button>
            </div>
            <hr className="my-2 h-1 mx-auto bg-blue-100" />
          </div>

          <table className="w-full border-collapse border border-slate-500 text-left">
            <thead>
              <tr>
                <th className="border border-slate-600 p-2">Prot./Anno</th>
                <th className="border border-slate-600 p-2">Nome e cognome</th>
                <th className="border border-slate-600 p-2">Data Esame</th>
                <th className="border border-slate-600 p-2">Data Rilascio</th>
                {/*<th className="border border-slate-600 p-2">Anno</th>*/}
                <th className="border border-slate-600 p-2">Corso</th>
                <th className="border border-slate-600 p-2">Codice Fiscale</th>
                {/* <th className="border border-slate-600 p-2">Pagato</th> */}

                {localStorage.getItem("role") === "admin" && (
                  <>
                    <th className="border border-slate-600 p-2">
                      Data Nascita
                    </th>
                    <th className="border border-slate-600 p-2">
                      Città Nascita
                    </th>
                  </>
                )}
                <th className="border border-slate-600 p-2">Azioni</th>
              </tr>
            </thead>
            <tbody>
              {certificates?.map((item, index) => (
                <tr className="even:bg-gray-70 odd:bg-white" key={index}>
                  <td className="border border-slate-700 p-2 py-4">
                    {item?.progress}/{item?.aa}
                  </td>
                  <td className="border border-slate-700 p-2 py-4">
                    {item?.name + " "} {item?.surname}
                  </td>
                  <td className="border border-slate-700 p-2 py-4">
                    {formatDate(item?.examDate)}
                  </td>
                  <td className="border border-slate-700 p-2 py-4">
                    {formatDate(item?.releasedAt)}
                  </td>
                  <td className="border border-slate-700 p-2 py-4">
                    {item?.courseType}
                  </td>
                  <td className="border border-slate-700 p-2 py-4">
                    {item?.fiscalCode}
                  </td>
                  {localStorage.getItem("role") === "admin" && (
                    <>
                      <td className="border border-slate-700 p-2 py-4">
                        {formatDate(item?.dateOfBirth)}
                      </td>
                      <td className="border border-slate-700 p-2 py-4">
                        {item?.birthCity}
                      </td>
                    </>
                  )}
                  <td className="border border-slate-700 p-2 py-4">
                    <div className="grid grid-cols-3 gap-2">
                      {localStorage.getItem("role") === "admin" && (
                        <div
                          className="cursor-pointer"
                          onClick={() => toggleEditCertificate(item)}>
                          <FaRegEdit size={24} />
                        </div>
                      )}
                      <div
                        className="cursor-pointer"
                        onClick={() => toggleDownloadCertificate(item)}>
                        <FaDownload size={24} />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={`${styles.pagination} flex flex-wrap justify-center`}>
            <div className="cursor-pointer" onClick={() => prevPage()}>
              Indietro
            </div>
            {getPagination()?.map((item, index) => (
              <div
                className={`${styles.pageNumber} ${
                  pagination === index ? styles.pageActive : ""
                }`}
                onClick={() => goToPage(item)}
                key={index}>
                {item}
              </div>
            ))}
            {pagination < pages && (
              <div className="cursor-pointer" onClick={() => nextPage()}>
                Avanti
              </div>
            )}
          </div>
        </div>
      </Layout>

      <DialogEditAdminCertificate
        certificate={certificate}
        userData={userData}
        userid={currentUser}
        open={dialogEditCertificate}
        handler={toggleEditCertificate}
        setDialogEditCertificate={setDialogEditCertificate}
      />

      <DialogDeleteAdminCertificate
        open={dialogDeleteCertificate}
        handler={toggleDeleteCertificate}
      />

      <DialogDownloadAdminCertificate
        open={dialogDownloadCertificate}
        handler={toggleDownloadCertificate}
        setDialogDownloadCertificate={setDialogDownloadCertificate}
        item={qrCodeData}
      />
    </>
  );
}
