import moment from "moment";
import "moment-timezone";

export const courseUtils = {
  time(startedAt, maxTime) {
    let today = moment(new Date()).tz("Europe/Rome");
    let couseDate = moment(startedAt).tz("Europe/Rome");
    let difference = moment.duration(today.diff(couseDate));

    let hours = maxTime - Math.trunc(difference.asHours());

    return hours || 0;
  },
  hours(startedAt) {
    let today = moment(new Date());
    let courseDate = moment(startedAt).format("YYYY-MM-DD HH:mm:ss");
    let difference = moment.duration(today.diff(courseDate));
    let hours = Math.trunc(difference.asHours());
    return hours;
  },
  // return hour from data
  hour(startedAt) {
    if (startedAt) {
      let dateHour = new Date(startedAt).getHours();
      let dateMinute = new Date(startedAt).getMinutes();
      return dateHour + ":" + dateMinute;
    }
  },
};
