import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

function Protected({ userToken, children }) {
  let navigate = useNavigate();

  const navigateToLogin = () => {
    navigate("/login", {});
  };

  useEffect(() => {
    if (!userToken) {
      navigateToLogin();
    }
  }, []);

  return children;
}

export default Protected;
