import { useEffect, useState } from "react";
import { FaRegBuilding } from "react-icons/fa";
import UpoloadFile from "../uploadFile";
import { courseService } from "../../api/course";
import { adminService } from "../../api/admin";

function BankPayment(props) {
  const { userData, course, registrationCode, setIsFileUploaded } = props || "";

  const [priceDomain, setPriceDomain] = useState(0);
  const [priceDiscountDomain, setPriceDiscountDomain] = useState(0);
  const [/*appConfig,*/ setAppConfig] = useState();

  const token = localStorage.getItem("token");

  /** */
  const getAppConfig = () => {
      adminService.getAppConfig({ token }).then((response) => {
          setAppConfig(response?.data?.payload);
      }).catch((error) => {
      })
  }//getAppConfig

  const getPriceByUserDomain = () => {
    const params = {
      token,
      courseCode: course.code
    };

    courseService.getPriceByDomain({ params })
      .then((response) => {
        //console.log(response?.data?.payload)
        setPriceDomain((response?.data?.payload?.price > 0) ? response?.data?.payload?.price : course.price);
        setPriceDiscountDomain((response?.data?.payload?.priceDiscount > 0) ? response?.data?.payload?.priceDiscount : course.priceDiscount);
      })
      .catch((error) => { });
  }//getPriceByUserDomain

  useEffect(() => {
    getAppConfig();
    getPriceByUserDomain();
    // eslint-disable-next-line
  }, [])

  //--- ---

  return (
    <div className="mt-8 bg-white p-4">
      <div className="flex flex-wrap items-center mb-4">
        <FaRegBuilding size={36} />
        <p className="text-xl ml-2 font-bold">Bonifico</p>
      </div>
      <hr />
      <div className="mt-4">
        <p>
          Effettua il pagamento attraverso un bonifico bancario intestato a:
        </p>
        <p className="font-bold">Lab.Mar. di Calogero Cecato</p>
        <p className="font-bold">IT42A0306983170100000008840</p>
        <p>Intesa San Paolo</p>
        <p>Sciacca (AG)</p>
        <p>
          <span className="font-bold">Causale:</span>{" "}
          {/* {userData?.name ? userData?.name : "Nome"}{" "} */}
          {course?.userCertification?.name
            ? course?.userCertification?.name
            : "Nome"}{" "}
          {/* {userData?.surname ? userData?.surname : "e Cognome"} {course?.title}{" "} */}
          {course?.userCertification?.surname
            ? course?.userCertification?.surname
            : "e Cognome"}{" "}
          {course?.title}{" "}
          Importo: <span className="font-bold">€</span>{" "}
          {userData?.taxID && <span className="font-bold">36.00</span>}
          {userData?.fiscalCode && (
            <span className="font-bold">
            {/*userData?.userBonus ? (((parseFloat(priceDiscountDomain) * parseFloat(appConfig?.data[0]?.iva || 0))/100)+parseFloat(priceDiscountDomain)).toFixed(2) : (((parseFloat(priceDomain) * parseFloat(appConfig?.data[0]?.iva || 0))/100)+parseFloat(priceDomain)).toFixed(2)*/}
            {userData?.userBonus ? (parseFloat(priceDiscountDomain)).toFixed(2) : (parseFloat(priceDomain)).toFixed(2)}
            </span>
          )}
        </p>
        <p>(si consiglia di effettuare un bonifico istantaneo)</p>
      </div>
      <div className="mt-4">
        <p>Se hai già effettuato il bonifico carica la ricevuta qui:</p>
        <div className="mt-2">
          <UpoloadFile
            course={course}
            userData={userData}
            registrationCode={registrationCode}
            setIsFileUploaded={setIsFileUploaded}
          />
        </div>
      </div>
    </div>
  );
}

export default BankPayment;
