import React from "react";

import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
  DialogFooter,
  Card,
  CardBody,
  CardFooter,
} from "@material-tailwind/react";

import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import PdfCreate from "../pdfCreate";
import { HiDownload } from "react-icons/hi";
import { QRCodeCanvas } from "qrcode.react";

function DialogDownloadAdminCertificate(props) {
  const { open, handler, item } = props;

  const generatePDFDocument = async (item) => {
    let base64Image = document.getElementById("qrcode").toDataURL();

    item = { ...item };

    if (base64Image) {
      const blob = await pdf(
        <PdfCreate qrcode={base64Image} data={item} />
      ).toBlob();
      saveAs(
        blob,
        "certificato-haccp-" + item?.item?.name + "-" + item?.item?.surname
      );
    }
  };

  //--- ---

  return (
    <Dialog open={open} handler={handler}>
      <DialogHeader>Download Certificato</DialogHeader>

      <DialogBody>
        {/* certificates list */}
        <div className="mt-4 bg-white p-4">
          <div className="my-4 text-center mx-auto">
            <Card
              className="w-full lg:w-96 cursor-pointer mb-4 mx-auto"
              onClick={() => generatePDFDocument({ item })}>
              <CardBody className="text-center">
                {item?.releasedAt ? (
                  <div className="flex flex-wrap justify-center mt-4 mb-4">
                    <QRCodeCanvas
                      value={
                        "https://app.labmar.it/verifyQrCode?verify=" +
                        item?.code +
                        ""
                      }
                      id="qrcode"
                    />
                  </div>
                ) : null}
              </CardBody>
              {item?.releasedAt ? (
                <CardFooter className="flex justify-center gap-7 pt-2">
                  <div>
                    <p>Scarica</p>
                    <HiDownload size={48} />
                  </div>
                </CardFooter>
              ) : null}
            </Card>
          </div>
        </div>
      </DialogBody>

      <DialogFooter>
        <Button variant="text" color="red" onClick={handler} className="mr-1">
          <span>chiudi</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default DialogDownloadAdminCertificate;
