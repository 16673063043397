import React from "react";

function Content({ children }) {
  return (
    <div className="bg-gray-100 m-2 md:m-4 md:p-4 rounded-3xl h-full shadow-lg">
      {children}
    </div>
  );
}

export default Content;
