import React from "react";

import { Logout } from "../../../../utils/logout";

import { BiLogOut } from "react-icons/bi";

export default function MenuTopBar() {
  return (
    <div
      onClick={() => Logout()}
      className="flex flex-wrap items-center cursor-pointer">
      <BiLogOut />
      <span className="font-semibold ml-1 text-sm">Logout</span>
    </div>
  );
}
