import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { userService } from "../api/user";

function ProtectedAdmin({ userToken, children }) {
  const [user, setUser] = useState(null);

  const token = localStorage.getItem("token");
  let navigate = useNavigate();

  const getUser = () => {
    userService.getUser({token}).then((response) => {
      setUser(response?.data?.payload[0]);
    }).catch((error) => {
      navigateToLogin("/login", {});
    })
  }

  const navigateToLogin = () => {
    navigate("/login", {});
  };

  useEffect(() => {
    if (!userToken) {
      navigateToLogin();
    }
    getUser();
    // eslint-disable-next-line
  }, [setUser]);

  if(!user) {
    return null;
  }

  if(!['admin', 'tutor'.includes(user?.role)]) {
    navigateToLogin();
  }

  return children;
}

export default ProtectedAdmin;
