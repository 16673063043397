import React from "react";

import {
  Button,
  Dialog,
  DialogHeader,
  DialogFooter,
} from "@material-tailwind/react";

function DialogDeleteAdminCertificate(props) {
  const { open, handler } = props;

  const handleDeleteCertificate = () => {};

  return (
    <Dialog open={open} handler={handler}>
      <DialogHeader>
        Sei sicuro di voler cancellare il certificato?
      </DialogHeader>
      <DialogFooter>
        <Button variant="text" color="red" onClick={handler} className="mr-1">
          <span>No, annulla</span>
        </Button>
        <Button
          variant="gradient"
          color="green"
          onClick={() => handleDeleteCertificate()}>
          <span>Si, cencella certificato</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default DialogDeleteAdminCertificate;
