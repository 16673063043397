import React from "react";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

import { formatDate } from "../../utils/date";

Font.register({
  family: "Roboto",
  src: "https://fonts.googleapis.com/css2?family=Roboto&display=swap",
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
  },
  section: {
    margin: 10,
    marginBottom: 0,
    marginTop: 0,
    padding: 10,
  },
  question: {
    margin: 10,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 8,
  },
  header: {
    margin: 20,
    marginBottom: 0,
    padding: 10,
    paddingBottom: 0,
    textAlign: "center",
  },
  lineContainer: {
    margin: 'auto',
    marginTop: 0,
    marginBottom: 0,
    width: '90%', // Imposta la larghezza al 90% del contenitore
  },
  line: {
    borderBottom: 1,
    borderColor: 'black',
    marginBottom: 10,
  },
  fontBold: {
    fontWeight: "900",
  },
});


// Create Document Component
const PdfCreateExam = (props) => {

  return (
    <Document>
      <Page size="A4" style={styles.page}>

        <View style={styles.header}>
          <Image
            style={{ width: "50px", height: "50px", margin: "0 auto" }}
            src={"/images/logo.png"}
          />
          <Text style={{ fontSize: "14px", marginTop: "20px" }}>
            Lab.Mar. del Dott. Calogero Cecato
          </Text>
          <Text
            style={{ fontWeight: "900", fontSize: "12px", marginTop: "5px" }}
          >
            STUDIO CONSULENZA HACCP
          </Text>
          <Text style={{ fontSize: "10px", marginTop: "5px" }}>
            Iscritto al n. 2021/356 del registro regionale della regione Sicilia
            per la formazione degli alimentaristi
          </Text>
        </View>

        <View style={styles.section}>
          <Text
            style={{
              fontSize: "13px",
              fontWeight: "800",
              textAlign: "center",
              marginTop: "0px",
              marginBottom: "14px",
            }}
          >
            Esame {props.data?.courseCode || '*'} superato su {props.data?.domain || '*'} da:
          </Text>

          <div className="flex">
            <Text
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {props.data?.name || '*'} {props.data?.surname || '*'}
            </Text>
          </div>

          <Text
            style={{
              fontSize: "10px",
              fontWeight: "bold",
              textAlign: "center",
              marginTop: "5px",
            }}
          >
            codice fiscale: {props.data?.fiscalCode || '*'} - email: {props.data?.email || ''}
          </Text>

          <Text
            style={{
              fontSize: "10px",
              lineHeight: "1.3",
              textAlign: "center",
              marginTop: "5px",
            }}
          >
            data esame: {formatDate(props.data?.examDate) || ''} - codice registrazione: {props.data?.registrationCode || ''}
          </Text>
        </View>

        <View style={styles.lineContainer}>
          <View style={styles.line}></View>
        </View>

        <View style={styles.section}>
          {
            props.questions[0].map((item, idx) => {
              //break={idx > 0} wrap={false}
              return <View style={styles.question} key={idx} >
                <Text
                  style={{
                    fontSize: "11px",
                    textAlign: "left",
                    marginTop: "5px"
                  }}
                >
                  Domanda {idx + 1}
                </Text>
                <Text
                  style={{
                    fontSize: "11px",
                    textAlign: "left",
                    marginTop: "5px"
                  }}
                >
                  {item.question}
                </Text>
                {
                  props.questions[1].filter((itemAns)=>{ return itemAns.userQuizQuestionAttemptId === item.id}).map((itemAns, idx) => {
                    return (<View>
                      <Text
                      style={{
                        fontSize: "11px",
                        textAlign: "left",
                        marginTop: "5px"
                      }}
                    >
                      {(idx+1)+') '+itemAns.answer} {' '} {(itemAns.userAnswer === 0) ? 'X' : 'V'}
                    </Text>
                    </View>)
                  })
                }
              </View>
            })
          }
        </View>

      </Page>
    </Document>
  );
};

export default PdfCreateExam;
